import React,{useState,useEffect} from 'react'
import {Row, Col, Table, ModalBody} from 'react-bootstrap'
import styles from './morningStar.module.css'
import open from '../../public/images/open.svg'
import CustomModal from '../../components/CustomModal/CustomModal'
import { decimalAdjust } from '../../utils'
import moment from 'moment'
import { useHistory } from 'react-router-dom'
import { getCurrentAccountStatusLink } from '../../common'
import { MORNING_STAR_FEATURE } from '../../constants'

const HoldingTable = ({holdingData,premiumFeatures,activePlan}) =>{
  const history = useHistory()
  const [showModal, setShowModal] = useState(false)
  const [substractWidth, setSubstractWidth] = useState(`calc(50vw)`)
  const handleClose = () => setShowModal(false)
  const handleShow = () => {
    setShowModal(true)
  }
  useEffect(()=>{
    if(window.screen.width <= 425){
      setSubstractWidth(`calc(100vw)-10px`)
    }
  },[])

  const dateFormatter = (data) => {
    let year = data.slice(0,4)
    let month = data.slice(5,7)
    let day = data.slice(8,10)
    month = moment().month(month-1).format("MMM")
    return `${day} ${month} ${year}`
  }

  const showData = holdingData && holdingData.map((item,i)=>{
    return(
      <tr style={{background:i%2!==0 ? '#F7F7F8' : '#FFFFFF'}}>
      <td className={styles.code}>{item.securityname}</td>
      <td className={styles.rest}>{item.holdingassettype}</td>
      <td className={styles.rest2} style={{float:'right'}}>
        <div style={{width:'63px'}}>
        {decimalAdjust('floor',item.weightagepct,-2)}%
        </div>
        </td>
    </tr>
    )
  })

  const showLimitedData = holdingData && holdingData.slice(0,10).map((item,i)=>{
    return(
      <tr style={{background:i%2!==0 ? '#F7F7F8' : '#FFFFFF'}}>
        <td className={styles.code}>{item.securityname}</td>
        <td className={styles.rest}>{item.holdingassettype}</td>
        <td className={styles.rest2} style={{float:'right'}}>
        <div style={{width:'68px'}}>
        {decimalAdjust('floor',item.weightagepct,-2)}%
        </div>
        </td>
      </tr>
    )
  })

  const redirectProfile = async() => {
    //logic to redirect user based on account status
    const link = await getCurrentAccountStatusLink();
    history.push(link)
  }

  return(
    <>
    <Table striped>
          <thead>
            <tr className={styles.headingCss}>
              <th className={styles.instrument}>Instrument</th>
              <th>Asset Class</th>
              <th className={styles.allocation}>Allocation</th>
            </tr>
          </thead>
          <tbody>
            {showLimitedData}
          </tbody>
    </Table>


{ premiumFeatures.includes(MORNING_STAR_FEATURE) && activePlan === 'active' ?
    <div>
      <span className={styles.seeAll} onClick={()=>handleShow()}>See all Holdings</span>
      <img src={open} style={{marginLeft:'11.5px'}} onClick={()=>handleShow()}/>
    </div> :
    <div>
      <span className={styles.seeAll} onClick={()=>redirectProfile()}>Upgrade to see all holdings</span>
    </div>
    }
    <hr className={styles.horizontalLine}></hr>

    <CustomModal show={showModal} handleClose={handleClose} fromEtf={true} scrollable={true}>
      <div style={{position:"sticky",top:"0px",zIndex:"50",background:"white"}}>
      <span
        className={styles.holding}
      >
        Holdings
      </span>
      <span>
      <span className={styles.net} style={{marginLeft:"8px"}}>{`[As of ${dateFormatter(holdingData && holdingData.length && holdingData[0].asofdate)}]`}</span>
      </span>
      </div>
      <div style={{position:"sticky",zIndex:'10'}} className={styles.tableResponsive}>
         <Table striped className={styles.fixingMorningStar}
          // style={{width:`${substractWidth}`}}
          // style={{width:'calc(50vw)'}}
          >
          <thead>
            <tr className={styles.headingCss}>
              <th className={styles.instrument}>Instrument</th>
              <th>Asset Class</th>
              <th className={styles.allocation}>Allocation</th>
            </tr>
          </thead>
          <tbody className={styles.morningStarTableBody}>
            {showData}
          </tbody>
        </Table>
        </div>
    </CustomModal>

    </>
  )
}

export default HoldingTable

{/* <div>
        <span className={styles.holding}>Holdings</span>
        <span className={styles.net} style={{marginLeft:"8px"}}>{`[As of ${dateFormatter(holdingData)}]`}</span>
        </div> */}

{/* <>
    <table className="table table-striped">
      <thead>
        <Row className={styles.responsiveRow}>
          <Col className={styles.responsiveInstrument}>
            <div>
              Instrument
            </div>
          </Col>
          <Col className={styles.responsiveCol}>
            <div style={{textAlign:'end'}} className={styles.responsiveAllocation}>
              Allocation
            </div>
          </Col>
        </Row>
      </thead>
      <div className={styles.gradientBox}>
        </div>
      <tbody>
        {showLimitedData}
      </tbody>
    </table>
    <span className={styles.seeAll} onClick={()=>handleShow()}>See all Holdings</span>
    <img src={open} style={{marginLeft:'11.5px'}} onClick={()=>handleShow()}/>
    <hr className={styles.horizontalLine}></hr>

    <CustomModal show={showModal} handleClose={handleClose} fromEtf={true}>

      <div className={styles.fixedHolding}>
        <Row className={styles.holdingResponsive} style={{marginTop:"10px"}}>
          <Col>
            <div className={styles.holding}>Holdings</div>
          </Col>
        </Row>
        <Row style={{margin:"0px"}}>
            <Col className={styles.responsiveInstrument}>
              <div>
                Instrument
              </div>
            </Col>
            <Col className={styles.responsiveCol}>
              <div style={{textAlign:'end'}} className={styles.responsiveAllocation}>
                Allocation
              </div>
            </Col>
        </Row>
      </div>
      <div style={{marginTop:"100px"}}>
      </div>
      <table className="table table-striped" style={{width:`${substractWidth}`}}>
        <tbody>
          {showData}
        </tbody>
      </table>
    </CustomModal>
    </> */}
