import React, { useState, useEffect, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Container, Row, Col, Card,Modal,Button } from 'react-bootstrap'
import Switch from "react-switch";
import open from '../../public/images/open.svg'
import {
  Header,
  StockDetails,
  StockFinancial,
  StockDetailGraph,
  StockSeeMore,
  StockReview,
  TrendingStocksCard,
  Footer,
  BuyCard,
  ReviewOrderCard,
  CostDetailCard,
  IndividualStack,
  StackCard,
  Etfotherinfo,
  MobileBuyButton,
  SideBar,
  MorningStar,
  ExtendedDisclosureModal,
  ExtendedCard
} from '../../components'
import Slider from 'react-slick'
import $ from 'jquery'
import styles from './etfdetail.module.css'
import MobileStockAbout from '../../components/StockAbout/MobileStockAbout'
import EmptyPage from '../../components/EmptyPage/EmptyPage'
import {STOCK_PAGE,PORTFOLIO_LIVE_PRICE_INTERVAL } from '../../constants'
import {
  getStockData,
  getSimilarStock,
  getLikedStacks,
  getStockDetails,
  addInWatchlist,
  getLivePrice,
  getShareFundamentals,
  getEtfDetails,
  watchListDataAPI,
  deleteWatchListTicker,
  getHoldingDetails,
  getSectorDetails,
  getAssetDetails,
  getIndustryDetails,
  getRatingsDetails,
  getAccountInfo,
  getHighlights
} from '../../api'
import StockHeadingSkeleton from '../StockDetail/StockHeadingSkeleton'
import { getMarketDetails } from '../../store/actions/index'
import theme from '../../components/ExploreSearch/Theme'
import watchlistStar from '../../public/images/watchlistStar.svg'
import addedStarImage from '../../public/images/added.svg'
import StatsModal from './StatsModal'
import StockHighlights from '../../components/StockDetail/StockHighlights';
import { ThemeContext } from '../../Context/ThemeContext';


const EtfDetail = props => {
  const dispatchAction = useDispatch()
  const [stockName, setStockName] = useState(props.match &&
    props.match.params &&
    props.match.params.code &&
    props.match.params.code)
  const [review, setReview] = useState(false)
  const [similarData, setSimilarData] = useState([])
  const [likedData, setLikedData] = useState([])
  const [stockData, setStockData] = useState([])
  const [inWatchList, setInwatchList] = useState(false)
  const [watchList, setWatchList] = useState([])
  const [loading, setloading] = useState(true)
  const [stockLivePrices, setStockLivePrices] = useState({});
  const [etfData, setEtfData] = useState({})
  const [livePrice, setLivePrice] = useState({})
  const [shareData, setSharedData] = useState({})
  const [starRotate, setStarRotate] = useState(false)
  const [stockNotFound, setStockNotFound] = useState(false)
  const [slideeffect, setslideeffect] = useState(false)
  const [activePlan, setActivePlan] = useState()
  // const [exclusive, setExclusive] = useState()
  const [premiumFeatures, setPremiumFeatures] = useState([])
  const [holdingData, setHoldingData] = useState()
  const [sectorData, setSectorData] = useState()
  const [assetData, setAssetData] = useState()
  const [industryData, setIndustryData] = useState()
  const [ratingData,setRatingData] = useState()
  const [showExtended,setShowExtended] = useState(false)
  const [showDisclouser,setShowDisclouser] = useState(false)
  const [acceptExtendedDisclosure,setacceptExtendedDisclosure] = useState(false)
  const [showStatsModal, setShowStatsModal] = useState(false)
  const [userLoading,setUserLoading]= useState(true)
  const [isOtc, setIsOtc] = useState(false)
  const [highlightsData, setHighlightsData] = useState({})

  const { siteFeatures } = useContext(ThemeContext)
  const state = useSelector(state => state)
  const {
    marketDetails: { marketDetails: { currentMarketStatus = {} } = {} } = {}
  } = state
  Object.keys(state.marketDetails).length === 0 &&
    dispatchAction(getMarketDetails())


  const getProfile = async() => {
    const userInfores = await getAccountInfo();
    if (userInfores && userInfores.statusDetails){
      sessionStorage.setItem('userInfo', JSON.stringify(userInfores));
      setUserLoading(false)
    }
  }

  useEffect(() => {
  	let sessionProfileData=''
		sessionProfileData = sessionStorage.getItem('userInfo') ? JSON.parse(sessionStorage.getItem('userInfo')) : "";
		if(sessionProfileData  === ''){
			getProfile()
		}else{
      setUserLoading(false)
    }
  },[])

  useEffect(() => () => $(window).unbind('scroll'), [])

  useEffect(() => {
    stockName && watchList && watchList.map((item, i) => {
      item && item.code && item.code === stockName && setInwatchList(true)
    })
  }, [watchList, stockName])

  // useEffect(() => {
  //   $(window).scroll(function () {
  //     let scrollPos = parseInt(
  //       window.pageYOffset || document.documentElement.scrollTop
  //     )
  //     let boxHeight = parseInt($('.floatingEtf > div').height())
  //     let scrollStop = parseInt($('#scrollStopETF').position().top) - boxHeight - 50
  //     if (scrollPos > scrollStop) {
  //       $('.floatingEtf').css({ position: 'absolute', top: scrollStop - 100 })
  //     } else if (scrollPos < 200) {
  //       $('.floatingEtf').css({ position: 'relative' })
  //     } else {
  //       $('.floatingEtf').css({ position: 'fixed', top: 10 })
  //     }
  //   })
  // }, [])

  useEffect(() => {
    $(window).scroll(function () {
      let scrollPos = parseInt(
        window.pageYOffset || document.documentElement.scrollTop
      )
      let boxHeight = parseInt($('.floatingEtf > div').height())
      let scrollStop = parseInt($('#scrollStopETF').position().top) - boxHeight - 100
      if (scrollPos > scrollStop) {
        $('.floatingEtf').css({ position: 'absolute', top: scrollStop })
      } else if (scrollPos < 100) {
        $('.floatingEtf').css({ position: 'relative', top: 0 })
      } else {
        $('.floatingEtf').css({ position: 'fixed', top: 70 })
      }
    })
  }, [])

  const handleEffect = () => {
    setslideeffect(prevState => setslideeffect(!prevState))
  }
  const asynchFetch = async () => {
    try{
    const [similar, liked, data, live, shared, etf, list, holding, sector, asset, industry, rating, highlightsData] = await Promise.all([
      getSimilarStock(stockName),
      getLikedStacks(stockName),
      getStockDetails(stockName),
      getLivePrice(stockName),
      getShareFundamentals(stockName),
      getEtfDetails(stockName),
      watchListDataAPI(),
      getHoldingDetails(stockName),
      getSectorDetails(stockName),
      getAssetDetails(stockName),
      getIndustryDetails(stockName),
      getRatingsDetails(stockName),
      getHighlights(stockName)
    ])
    setSimilarData(similar)
    setLikedData(liked)
    setStockData(data && { ...data })
    setIsOtc(data && data.isOTC)
    setHighlightsData(highlightsData)
    stockName && live && live[stockName] && setLivePrice(live[stockName])
    setSharedData(shared)
    setEtfData(etf)
    setWatchList(list)
    setHoldingData(holding)
    setSectorData(sector)
    setAssetData(asset)
    setIndustryData(industry)
    setRatingData(rating)
    if((data.isOTC === false && data.listed === false && shared === 'No holdings available.') || data.stockType !== 'etf'){
      setStockNotFound(true)
    }
    else{
      setloading(false)
    }
  }catch(error){
    setStockNotFound(true)
  }
  }



  useEffect(() => {
    setStockName(props.match &&
      props.match.params &&
      props.match.params.code &&
      props.match.params.code)

  }, [props.match &&
    props.match.params &&
    props.match.params.code &&
    props.match.params.code])

  useEffect(()=>{
    setShowExtended(false,setShowDisclouser(false))
    setInwatchList(false, asynchFetch())
  },[stockName])

  var settings2 = {
    dots: false,
    className: 'center',
    autoplaySpeed: 3000,
    infinite: true,
    centerMode: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          centerPadding:'10px'
        }
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding:'20%'
        }
      },
      {
        breakpoint:480,
        settings: {
          slidesToShow:1,
          slidesToScroll:1,
          centerMode:true,
          centerPadding:'20%'
        }
      },
      {
        breakpoint:425,
        settings: {
          slidesToShow:1,
          slidesToScroll:1,
          centerMode:true,
          centerPadding:'15%'
        }
      },
      {
        breakpoint:375,
        settings: {
          slidesToShow:1,
          slidesToScroll:1,
          centerMode:true,
          centerPadding:'20px'
        }
      },
    ]
  }

  useEffect(() => {
    JSON.parse(sessionStorage.getItem('planInfo')) && JSON.parse(sessionStorage.getItem('planInfo')).details &&
      JSON.parse(sessionStorage.getItem('planInfo')).details.premiumFeatures? setPremiumFeatures(JSON.parse(sessionStorage.getItem('planInfo')).details.premiumFeatures) : setPremiumFeatures([])
    JSON.parse(sessionStorage.getItem('planInfo')) && JSON.parse(sessionStorage.getItem('planInfo')).planStatus
      ? JSON.parse(sessionStorage.getItem('planInfo')).planStatus === 'active'
      ? setActivePlan(JSON.parse(sessionStorage.getItem('planInfo')).planStatus)
      : setActivePlan('expire')
      : setActivePlan('expire')
  }, [stockName])

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }, [stockName])

  useEffect(() => {
    const setLivePrice = async () => {
      try {
        let livePrices = await getLivePrice(stockName);
        setStockLivePrices(livePrices[stockName]);
      } catch (err) {
        console.log("error fetching live prices");
      }
    }
    setLivePrice();

    const priceInterval = setInterval(() => {
      if (currentMarketStatus === 'Open' || currentMarketStatus === 'Pre-Market' ||  currentMarketStatus === 'Post-Market') {
        setLivePrice()
      }
    }, PORTFOLIO_LIVE_PRICE_INTERVAL)
    return () => {
      clearInterval(priceInterval)
    }
  }, [stockName,currentMarketStatus])

  const addToWatchlist = async () => {
    if (!inWatchList) {
      const response = await addInWatchlist(stockName)
      response === 200 && setStarRotate(false, setInwatchList(true))
    } else {
      const res = await deleteWatchListTicker(stockName)
      res.code === 200 && setStarRotate(false, setInwatchList(false))
    }
  }

  return (
    <>
        <Header />
      <Container>
        {stockNotFound ? <EmptyPage type={STOCK_PAGE}/> :
       <>
        <Row>
        { loading || userLoading? (
              <StockHeadingSkeleton />
            ) : (
              <React.Fragment>
          <Col lg={7} xl={8}>
                <Row className='d-block d-lg-none' style={{ marginTop: '24px', marginBottom: '24px' }}>
                  <Col style={{ textAlign: "end", whiteSpace: "nowrap" }} className={styles.watchlistalignment}>
                    <div
                      style={{ width: '178px', maxWidth: '178px', display: 'inline-block', textAlign: "center" }}
                      className={styles.watchlist}
                      onClick={() => setStarRotate(true, addToWatchlist())}
                    >
                      <img className={starRotate ? styles.rotate : null} src={inWatchList ? addedStarImage : watchlistStar} />
                      <span
                        className={styles.watchlistText}
                      >
                        {
                          inWatchList ? 'Added to Watchlist' : 'Add to watchlist'
                        }
                      </span>
                    </div>
                  </Col>
                </Row>
                <Row className={styles.responsiveDetail}>
                  <Col>
                    <StockDetails
                    isOtc={isOtc}
                    stockName={stockName}
                    livePrices={stockLivePrices}
                    starRotate={starRotate}
                    setStarRotate={setStarRotate}
                    addToWatchlist={addToWatchlist}
                    inWatchList={inWatchList} />
                  </Col>
                </Row>
                {/* <Row className='mt-5 d-none d-lg-block'>
                  <Col>
                    <StockDetailGraph code={stockName} isOtc={isOtc}/>
                  </Col>
                </Row> */}
                <Row style={{margin:"0px"}}>
                    <StockHighlights highlightsData={highlightsData} isOtc={isOtc} stockName={stockName} isEtf={true}/>
                </Row>
                <div className='d-none d-lg-block'>
                  <hr className={`${styles.desktopLine}`} />
                </div>
                <div style={{ display: shareData ? shareData === 'No holdings available.' ? 'none' : 'block' : 'none' }}>
                  <hr className={`d-block d-lg-none ${styles.horizantalLine} ${styles.marginLine}`} />
                </div>
                <Row style={{ display: shareData ? shareData === 'No holdings available.' ? 'none' : 'block' : 'none', marginTop: "32px" }}>
                  <Col>
                    <CostDetailCard
                      data={shareData}
                      stockName={stockName}
                      livePrice={livePrice && livePrice.lastTrade && livePrice.lastTrade}></CostDetailCard>
                  </Col>
                </Row>
                <div className='d-none d-lg-block' >
                  <hr style={{ display: shareData ? shareData === 'No holdings available.' ? 'none' : 'block' : 'none' }} className={`${styles.shareLine}`} />
                </div>
            <hr className={`d-block d-lg-none ${styles.horizantalLine} ${styles.marginLine}`} />
            <Row className='d-block d-lg-none'>
              <Col>
                <MobileStockAbout stockName={stockName}
                  stockData={etfData && etfData}
                  etf={true}
                />
              </Col>
            </Row>
            <hr className={`d-block d-lg-none ${styles.horizantalLine} ${styles.marginLine}`} />
            {siteFeatures && siteFeatures.includes("etfFundamentals") ?
            <Row className={styles.reviewMargin}>
              <Col>
                <StockReview
                  stockName={stockName}
                  stockData={{
                    ...etfData,
                    'HighPriceLast52Weeks':(stockData?stockData.yearHigh:null),
                    'LowPriceLast52Weeks':(stockData?stockData.yearLow:null),
                  }}
                  assetData={assetData}
                  etf={true}
                />
              </Col>
            </Row> : ""}
             {siteFeatures && siteFeatures.includes("etfFinancialStats") ?
            <div onClick={()=>setShowStatsModal(true)} className={styles.statSeeAll}>
              <span>See detailed stats</span>
              <img src={open} style={{marginLeft:'11.5px'}}/>
            </div> : ""}
            <StatsModal etfData={etfData} showModal={showStatsModal} setShowModal={setShowStatsModal}/>
            {/* <Row className='mt-5'
                     style={{ display: stockData ? stockData.themes ? 'block' : 'none' : 'none' }}>
                  <Col>
                    <IndividualStack
                      themes={stockData && stockData.themes && stockData.themes}
                    />
                  </Col>
                </Row> */}
            {/* <hr className={`d-block d-lg-none ${styles.horizantalLine} ${styles.marginLine}`} /> */}
            <hr className={`d-none d-lg-block ${styles.shareLine}`} />
            <div style={{ display: etfData && etfData.BusinessDescription !== '' ? 'block' : 'none' }}>
              <hr className={`d-block d-lg-none ${styles.horizantalLine} ${styles.marginLine}`} />
            </div>
            {siteFeatures && siteFeatures.includes("otherInfo") ?
            <Row className={styles.responsiveEtf}>
              <Col>
                <Etfotherinfo etfData={etfData} code={stockName} />
              </Col>
            </Row> : ""}
            {
            ratingData && holdingData &&
            sectorData && assetData &&
            industryData &&
         (
          siteFeatures && siteFeatures.includes("morningstarRatings") ?
          <>
            <hr className={`d-none d-lg-block mb-0 ${styles.shareLine}`} />
            <hr className={`d-block d-lg-none ${styles.horizantalLine} ${styles.marginLine}`} />
            <Row>
              <Col>
              <MorningStar
                stockName={stockName}
                ratingData={ratingData}
                industryData={industryData}
                assetData={assetData}
                sectorData={sectorData}
                activePlan={activePlan}
                premiumFeatures={premiumFeatures}
                holdingData={holdingData}
                siteFeatures={siteFeatures}
              />
              </Col>
          </Row>
          </>
          : "")}
          <Row className='mt-5'
                     style={{ display: stockData ? stockData.themes ? 'block' : 'none' : 'none' }}>
                  <Col>
                    <IndividualStack
                      themes={stockData && stockData.themes && stockData.themes}
                    />
                  </Col>
                </Row>
          </Col>
          <Col lg={5} xl={4} className={`d-none d-lg-block`}>
            {/* create extended hour  */}
                <ExtendedCard
                   isOtc={isOtc}
                   showExtended={showExtended}
                   setShowExtended={setShowExtended}
                   setShowDisclouser={setShowDisclouser}
                   />
            {/* <div className={styles.watchlistDiv}>
              <div
                onClick={() => setStarRotate(true, addToWatchlist())}
                style={{ width: '178px', maxWidth: '178px', display: 'inline-block', textAlign: "center" }}
                className={styles.watchlist}
              >
                <img className={starRotate ? styles.rotate : null} src={inWatchList ? addedStarImage : watchlistStar} />
                <span className={styles.watchlistText}>{inWatchList ? 'Added to Watchlist' : 'Add to watchlist'}</span>
              </div>
            </div> */}
            <div className='floatingEtf'>
              <BuyCard
                isOtc={isOtc}
                stockName={stockName}
                livePrices={stockLivePrices}
                stockData={stockData}
                shareData={shareData}
                marketState={currentMarketStatus}
                showExtended={showExtended}
              />
            </div>
          </Col>
          </React.Fragment>
            )}
        </Row>
        <div id='scrollStopETF'></div>
        <div style={{ display: likedData && likedData.length ? 'block' : 'none' }}>
          <hr className={`${styles.shareLine}`} />
        </div>
        <Row>
          <Col style={{ paddingLeft: '15px', paddingRight: '15px' }}>
            <Row
              className={styles.cardrowMargin}
              style={{
                display: likedData && likedData.length ? 'block' : 'none'
              }}>
              <Col className={styles.heading} style={{ marginBottom: '4px', paddingLeft: '0px' }}>
                Stacks you may like
              </Col>
            </Row>
            <Row
              className={styles.subHeading}
              style={{
                display: likedData && likedData.length ? 'block' : 'none'
              }}>
              <Col>Expert made model portfolios</Col>
            </Row>
            <Row
              className={`d-none d-md-none d-lg-none d-xl-flex ${styles.cardtopMargin}`}
              style={{
                marginBottom: '60px',
                display: likedData && likedData.length ? 'block' : 'none'
              }}>
              {likedData &&
                likedData.slice(0, 4).map((item, index) => {
                  return (
                    <Col key={index} md={3} xs={9}>
                      <StackCard
                        image={item.logo}
                        ticker={item.ticker}
                        createdBy={item.createdByTitle}
                        desc={item.description}
                        cagr={item.cagr}
                        minInvest={item.minimumInvestment}
                      />
                    </Col>
                  )
                })}
            </Row>
            <Row
              className={`d-none d-md-none d-lg-flex d-xl-none ${styles.cardtopMargin}`}
              style={{
                marginBottom: '60px',
                display: likedData && likedData.length ? 'block' : 'none'
              }}>
              {likedData &&
                likedData.slice(0, 3).map((item, index) => {
                  return (
                    <Col key={index} md={4} xs={9}>
                      <StackCard
                        image={item.logo}
                        ticker={item.ticker}
                        createdBy={item.createdByTitle}
                        desc={item.description}
                        cagr={item.cagr}
                        minInvest={item.minimumInvestment}
                      />
                    </Col>
                  )
                })}
            </Row>
            <Row className='d-block d-lg-none' style={{ marginTop: "16px" }}>
              <Col>
                {likedData && likedData.length < 2 ?
                  likedData && likedData.map((item, index) => {
                  return (
                    <div style={{width:"256px"}}>
                        <StackCard
                        image={item.logo}
                        ticker={item.ticker}
                        createdBy={item.createdByTitle}
                        desc={item.description}
                        cagr={item.cagr}
                        minInvest={item.minimumInvestment}
                      />
                    </div>
                  )
                 }) :
                <Slider {...settings2} className={styles.trendingSlider}>
                  {likedData &&
                    likedData.slice(0, 4).map((item, index) => {
                      return (
                        <StackCard
                          image={item.logo}
                          ticker={item.ticker}
                          createdBy={item.createdByTitle}
                          desc={item.description}
                          cagr={item.cagr}
                          minInvest={item.minimumInvestment}
                        />
                      )
                    })}
                </Slider>}
              </Col>
            </Row>
          </Col>
        </Row>
         <Row className={`d-flex d-lg-none ${styles.buttonresponsive}`}>
           <Col style={{ padding: '0px' }}>
             <MobileBuyButton
               isOtc={isOtc}
               stockName={stockName}
               livePrices={stockLivePrices}
               stockData={stockData}
               shareData={shareData}
               marketState={currentMarketStatus}
             />
           </Col>
         </Row>
        </> }
      </Container>
      <div className={styles.sideBarMobileHide}>
        <SideBar handleEffect={handleEffect} change={inWatchList} pageType={'detail'} setInwatchList={setInwatchList} />
      </div>

      {/*  extended hour disclouser modal */}
      <ExtendedDisclosureModal
      showDisclouser={showDisclouser}
      setShowDisclouser={setShowDisclouser}
      setShowExtended={setShowExtended}
      acceptExtendedDisclosure={acceptExtendedDisclosure}
      setacceptExtendedDisclosure={setacceptExtendedDisclosure}
      />

    </>
  )
}

export default EtfDetail

