import axios from 'axios'

// var token = JSON.parse(localStorage.token);
// 	const headers = {
//     	'Authorisation': token.accessToken,
//    	};
const axiosCall = async (method, url, data = '', headers = '') => {
  try {
    // const accessToken = sessionStorage.getItem('token') ? JSON.parse(sessionStorage.getItem('token')).accessToken :'';
    let options = {
      method, url,
      // baseURL: HostV2
    }
    // if(accessToken){
    // 	options.headers = {
    // 	'Authorization':accessToken
    // 	}
    // }
    if (headers)
      options.headers = headers;
    if (data)
      options.data = data;

    const response = await axios(options);
    // if (response.status === 200) {
    return response.data
    // } else {
    //   return {
    //     status: 500,
    //     message: 'Oops! Something went wrong.'
    //   }
    // }
  } catch (err) {
    return {
      status: 500,
      message: 'Oops! Something went wrong.'
    }
  }
}


const axiosCall1 = async (method, url, data = '', headers = '') => {
  try {
    let options = {
      method, url,
    }
    if (headers)
      options.headers = headers;
    if (data)
      options.data = data;

    const response = await axios(options);
    return response.data
  } catch (err) {
    // console.error(err)
    if (err && err.response && err.response.data)
      return err.response.data
    else
      return { code: 500, message: 'Oops! Something went wrong.', data: null }
  }
}

export const getPopularStocks = async () => {
  try {
    const response = await axios.get('instrument/stocks?sortBy=ranking_monthly&filter[stock]=true&pageSize=8&page=1')
    return response.data.data
  } catch (error) {
    console.log(error)
  }
}

export const getStocksETF = async value => {
  try {
    const response = await axios.get(
      `instrument/stocks?sortBy=trending&filter[etf]=${value}`
    )
    return response.data.data
  } catch (error) {
    console.log(error)
  }
}

export const getStocksOfTheDay = async () => {
  try {
    const response = await axios.get('instrument/stocks?sortBy=stockOfTheDay')
    // console.log('stocks of day', response)
  } catch (error) {
    console.log(error)
  }
}

export const getGainerLoser = async value => {
  try {
    const response = await axios.get(`instrument/stocks?sortBy=${value}&pageSize=10&page=1`)
    // const response = await axios.get(`instrument/stocks?sortBy=${value}`)
    // console.log(response.data.data)
    return response.data.data
  } catch (error) {
    console.log(error)
  }
}

export const gethighLowStocks = async value => {
  try {
    const response = await axios.get(
      `instrument/stocks?sortBy=${value}&pageSize=10&page=1`
      // `instrument/stocks?sortBy=${value}&sortDirection=-1`
    )
    return response.data.data
  } catch (error) {
    console.log(error)
    return error.response
  }
}

export const getThemes = async () => {
  try {
    const response = await axios.get('instrument/themes')
    return response.data.data
  } catch (error) {
    console.log(error)
  }
}

export const getNews = async code => {
  try {
    const response = code
      ? await axios.get(`instrument/news?code=${code}`)
      : await axios.get('instrument/news')
    return response.data.data
  } catch (error) {
    console.log(error)
  }
}

export const getStockOfTheDay = async () => {
  try {
    // const response = await axios.get('instrument/stocks?sortBy=highesttraded&pageSize=10&page=1')
    const response = await axios.get('instrument/stocks?sortBy=highesttraded')
    return response.data.data
  } catch (error) {
    console.log(error)
  }
}

export const getTopPerformingStacks = async (value,value2) => {
  try {
    const response = await axios.get( value2 === undefined ?
      `stacks?sortBy=${value}` : `stacks?sortBy=${value}&riskMeter=${value2}`
    )
    return response.data.data
  } catch (error) {
    console.log(error)
  }
}

export const getPopularEtf = async () => {
  try {
    const response = await axios.get(
      `/instrument/stocks?sortBy=ranking_monthly&filter[etf]=true&pageSize=8&page=1`
      // 'instrument/stocks?sortBy=popular&filter[etf]=true'
    )
    return response.data.data
  } catch (error) {
    console.log(error)
  }
}
export const getPriceHistory = async (code, days) => {
  try {
    const response = await axios.get(
      `instrument/stocks/${code}/price-history?days=${days}`
    )
    return response.data.data
  } catch (error) {
    console.log(error)
  }
}
export const searchBarDataAPI = async searchValue => {
  try {
    const response = await axios.get(`instrument/stocks?search=${searchValue}&includeStacks=true&filter[etf]=true&filter[stock]=true&pageSize=20`)
    return response.data.data
  } catch (error) {
    console.log(error)
  }
}

export const getUserInvestmentHistory = async (days = '') => {
  try {
    const url = days === '' ? `users/portfolio/investments-history` : `users/portfolio/investments-history?days=${days}`
    const response = await axios.get(url)
    return response.data
  } catch (error) {
    console.log(error)
  }
}

export const getSnPHistory = async (index, days) => {
  try {
    const response = await axios.get(
      `indices/${index}/price-history?days=${days}`
    )
    return response.data.data
  } catch (error) {
    console.log(error)
  }
} // gives only top 50 data

export const watchListDataAPI = async () => {
  try {
    const response = await axios.get(`instrument/stocks/watchlist  `)
    return response.data.data
  } catch (error) {
    console.log(error)
  }
}

export const getAccountSummary = async () => {
  try {
    const response = await axios.get('users/accountSummary/summary')
    // console.log(response.data.data)
    return response.data
  } catch (error) {
    console.log(error)
  }
}

export const getAccountSummaryWithCommission = async () => {
  try {
    const response = await axios.get('users/accountSummary/summary-cash')
    if (response.data && response.data.code === 200) {
      return response.data.data;
    }
  } catch (error) {
    console.log(error)
  }
}

/**
 * Get Holdings
 */
export const getPortfolioData = async () => {
  try {
    const response = await axios.get('users/portfolio/detail')
    // console.log(response.data.data)
    return response.data.data
  } catch (error) {
    console.log(error)
  }
}

export const getAssetsPortfolio = async ()=>{
  try {
    const response = await axios.get('users/portfolio/fund-assets')
    return response.data.data
  } catch (error) {
    console.log(error)
  }
}

export const checkForNewHolding = async (timestamp) => {
  try {
    const response = await axios.get(`users/portfolio/delta?timestamp=${timestamp}`)
    if (response.data && response.data.code === 200) {
      return response.data;
    }
  } catch (error) {
    throw error.response
  }
}

export const getStackName = async stackName => {
  // v2 / instrument / AAPL
  try {
    const response = await axios.get(`stacks/${stackName}/allocation`)
    return response.data.data
  } catch (error) {
    console.log(error)
  }
}

export const getAccountInfo = async () => {
  try {
    const response = await axios.get('users/account-info')
    return response.data.data
  } catch (error) {
    console.log(error)
  }
}

//Logout post API's

export const logout = async data => {
  try {
    const response = await axios.post('logout-with-user', data)
    return response
  } catch (error) {
    console.log(error)
  }
}

export const getMarketTime = async date => {
  try {
    const response = await axios.get(`common/market/calender?date=${date}`)
    // console.log(response.data.data)
    return response.data
  } catch (error) {
    return error.response
    // console.log(error)
  }
}

export const getStackDetail = async code => {
  try {
    const response = await axios.get(`/stacks/${code}`)
    return response.data
  } catch (error) {
    return error.response.data
  }
}

export const getStackCommission = async (aum, entryFee, amount, aumFreq, ticker) => {
  try {
    const response = await axios.get(`/trades/commissions/stack`, {
      headers: {
        aum,
        entryFee,
        amount,
        aumFreq,
        ticker
      }
    })
    if (response.data && response.data.code === 200) {
      return response.data.data;
    }

  } catch (error) {
    console.log(error)
  }
}

export const getStockCommission = async (amount, quantity, type,side, symbol) => {
  try {
    const response = await axios.get(`/trades/commissions/stock`, {
      headers: {
        amount, quantity, type , symbol, side
      }
    })
    if (response.data && response.data.code === 200) {
      return response.data.data;
    }

  } catch (error) {
    console.log(error)
  }
}


// export const getStockData = async code => {
// 	try {
// 		const response = await axios.get(
// 			`/instrument/fundamentals/stocks/${code}`
// 		)
// 		return response.data.data
// 	} catch (error) {
// 		console.log(error)
// 	}
// }  last checked by jayesh returns empty object

export const getStockDetails = async code => {
  try {
    const response = await axios.get(`/instrument/${code}`)
    return response.data.data
  } catch (error) {
    throw error.response.data
  }
}



export const getLivePrice = async code => {
  try {
    const response = await axios.get(`/common/dw/live-price/${code}`)
    return response.data.data
  } catch (error) {
    console.log(error)
  }
}





export const getSimilarStock = async code => {
  try {
    const response = await axios.get(`/instrument/stocks/${code}/similar`)
    return response.data.data
  } catch (error) {
    console.log(error)
  }
}

export const getLikedStocks = async code => {
  try {
    const response = await axios.get(`stacks/${code}/similar-stocks`)
    return response.data.data
  } catch (error) {
    console.log(error)
  }
}

export const getLikedStacks = async code => {
  try {
    // const response = await axios.get(`/stacks/${code}/similar`)
    const response = await axios.get(`stacks/for-you?stockCode=${code}`)
    return response.data.data
  } catch (error) {
    console.log(error)
  }
}

export const getStockPriceDw = async (code, days) => {
  try {
    const response = await axios.get(
      `/instrument/stocks/dw-price?code=${code}&tradingDays=${days}`
    )
    return response.data.data
  } catch (error) {
    console.log(error)
  }
}
export const getHighlights = async code => {
  try {
    const response = await axios.get(`/instrument/stocks/${code}/highlights`)
    return response.data.data
  } catch (error) {
    console.log(error)
  }
}

// notification Api call
export const getNotification = async pagenumber => {
  // const headers = {
  // 	'Authorisation': token.accessToken,
  // };
  try {
    const response = await axios.get(`users/notification?page=${pagenumber}`)
    return response
  } catch (error) {
    console.log(error)
  }
}
export const getStackAllocation = async (code, amount) => {
  try {
    const response = await axios.get(
      `stacks/${code}/allocation?amount=${amount}`
    )
    return response.data.data
  } catch (error) {
    console.log(error)
  }
}

export const markAllNotification = async data => {
  // const headers = {
  // 	'Authorisation': token.accessToken,
  // };
  try {
    const response = await axios.post('users/notification/markAllRead', data)
    return response
  } catch (error) {
    console.log(error)
  }
}
export const verifySpin = async spin => {
  try {
    const response = await axios.post(`/users/spin?source=web`, { spin })
    if (response.data && response.data.code === 200) {
      return response.data;
    }
  } catch (error) {
    throw error.response
  }
}

export const getStackPriceHistory = async (ticker, days) => {
  try {
    const response = await axios.get(
      `stacks/${ticker}/price-history?days=${days}`
    )
    return response.data
  } catch (error) {
    return error.response
  }
}

export const getUserPortfolioDetail = async ticker => {
  try {
    const response = await axios.get(`users/portfolio/current/${ticker}`)
    if (response.data && response.data.code === 200) {
      return response.data.data
    }
  } catch (error) {
    throw error.response
  }
}

export const getCurrentValue = async (code, category) => {
  try {
    const response = await axios.get(`common/dw/current-value/${code}/${category}`)
    return response.data.data
  } catch (error) {
    throw error.response
  }
}

export const validateUserName = async userName => {
  try {
    const response = await axios.get(`onboarding/check-username/${userName}`)
    return response.data
  } catch (error) {
    return error.response
  }
}

export const getFund = async () => {
  try {
    const response = await axios.get(`users/accountSummary/summary`)
    return response.data.data
  } catch (error) {
    return error.response
  }
}

export const getBankAccount = async (type='add') => {
  try {
    const response = await axios.get(`bank/account?type=${type}`)
    return response.data.data
  } catch (error) {
    throw error.response
  }
}

export const generateOtp = async (email, reason="") => {
  try {
    let data;
    if(reason !== ""){
      data = { email, reason }
    } else {
      data = { email }
    }
    const response = await axios.post(`onboarding/otp`, data)
    return response.data
  } catch (error) {
    throw error.response
  }
}
export const getBankList = async () => {
  try {
    const response = await axios.get(`bank/banks`)
    if (response.data && response.data.code === 200) {
      return response.data
    }
  } catch (error) {
    throw error.response
  }
}

export const resendOtp = async hashID => {
  try {
    const data = { hashID }
    const response = await axios.post(`onboarding/resend-otp`, data)
    return response.data
  } catch (error) {
    return error.response
  }
}
export const getBrokerDetail = async () => {
  try {
    const response = await axios.get(`bank/lrs/brokerDetail`)
    return response.data
  } catch (error) {
    return error.response
  }
}
export const getVirtualAccountDetail = async () => {
  try {
    const response = await axios.get(`virtualAccount`)
    return response.data
  } catch (error) {
    return error.response
  }
}
export const updatedCreateAccount = async (data, screenDetails) => {
  let deviceData = screenDetails?screenDetails:{};
  if(navigator && navigator.userAgentData && navigator.userAgentData.getHighEntropyValues){
    try{
      deviceData.platformVersion = (await navigator.userAgentData.getHighEntropyValues(["platformVersion"])).platformVersion
    }catch(error){
      console.log('Error while parsing useragent')
    }
  }
  try {
    const response = await axios.post(`onboarding/create-account`, {...data, deviceMeta:deviceData})
    return response.data
  } catch (error) {
    if(error && error.response && error.response.data){
    throw error.response.data
    }
    else{
      throw new Error('Internet Connection lost')
    }
  }
}
export const postFund = async (fund, type) => {
  let temp={amount:fund}
  if(type)
    temp['source']=type
  try {
    const response = await axios.post(`bank/lrs/funds`, {...temp})
    return response.data
  } catch (error) {
    return error.response.data
  }
}

export const getMyFund = async id => {
  try {
    const response = await axios.get(`bank/lrs/funds/${id}`);
    return response.data
  } catch (error) {
    return error.response.data
  }
}


export const reviewKYC = async id => {
  try {
    const response = await axios.post('bank/instapay/kyc-review', { id });
    return response.data
  } catch (error) {
    return error.response.data
  }
}

export const updatedAccount = async data => {
  try {
    const response = await axios.post(`onboarding/user/update-account`, data)
    return response.data
  } catch (error) {
    throw error.response.data
  }
}
// remittance

export const getRemittanceHistory = async () => {
  try {
    const response = await axios.get(`trades/transactions/remittance`)
    console.log(response.data)
    return response.data.data
  } catch (error) {
    return error.response
  }
}

// export const fetchRedemptionDetail = async (ID) => {
//   try {
//     const response = await axios.get(`users/redemption/${ID}`)
//     return response.data.data
//   } catch (error) {
//     return error.response
//   }
// }





// const fetchRedemptionDetail = (ID) => {



// export const getRemittanceHistory = async () => {

//   try {
//     const response = await axios.get(
//       `https://api-v2.stockal.com/v2/trades/transactions/remittance`,
//       { headers }
//     )
//     return response.data.data
//   } catch (error) {
//     return error.response
//   }
// }




export const updatedforgotPassword = async username => {
  try {
    const data = { username }
    const response = await axios.post(`forgot-password`, data)
    return response.data
  } catch (error) {
    return error.response
  }
}

export const postAddBankAccount = async data => {
  try {
    const response = await axios.post(`bank/account`, data)
    if (response.data && response.data.code === 200) {
      return response.data
    }
  } catch (error) {
    throw error.response
  }
}
export const postAddBankAccountAddFund = async data => {
  try {
    const response = await axios.post(`bank/account?source=ADD_FUND`, data)
    if (response.data && response.data.code === 200) {
      return response.data
    }
  } catch (error) {
    throw error.response
  }
}
export const updateBankAccountAddFund = async (id, data) => {
  try {
    const response = await axios.put(`bank/account/${id}?source=ADD_FUND`, data)
    if (response.data && response.data.code === 200) {
      return response.data
    }
  } catch (error) {
    throw error.response
  }
}
export const postAddBankAccountPennyDrop = async data => {
  try {
    const response = await axios.post(`bank/account?bankVerification=true&source=ADD_FUND`, data)
    if (response.data && response.data.code === 200) {
      return response.data
    }
  } catch (error) {
    throw error.response
  }
}
export const updateBankAccountPennyDrop = async (id, data) => {
  try {
    const response = await axios.put(`bank/account/${id}?bankVerification=true&source=ADD_FUND`, data)
    if (response.data && response.data.code === 200) {
      return response.data
    }
  } catch (error) {
    throw error.response
  }
}
export const updateAddBankAccount = async (data, hashID) => {
  try {
    const response = await axios.put(`bank/account/${hashID}`, data)
    if (response.data && response.data.code === 200) {
      return response.data
    }
  } catch (error) {
    throw error.response
  }
}

export const getActiveCountryList = async () => {
  try {
    const response = await axios.get('common/dw/countries/active')
    if (response.data && response.data.code === 200) {
      return response.data
    }
  } catch (error) {
    return error.response
  }
}

export const getActiveCountryCitizenship = async () => {
  try {
    const response = await axios.get('common/dw/countries')
    if (response.data && response.data.code === 200) {
      return response.data
    }
  } catch (error) {
    return error.response
  }
}

export const getPartnerCountries = (type, countryCode) => {
  let url = countryCode ? `common/dw/list/${countryCode}` : 'common/dw/list';
  url = url+'?type='+type;
  return axiosCall1('GET', url);
}

export const geoCodingApi = async pincode => {
  try {
    const response = await axios.get(`common/pincode/${pincode}`)
    return response.data
  } catch (error) {
    return error.response
  }
}
export const putBrokerDetail = async (fundId,bankType) => {
  try {
    const response = await axios.put(`bank/lrs/${fundId}/brokerDetail`,{
      bankType
    })
    return response.data
  } catch (error) {
    return error.response
  }
}

export const putTransaction = async (hashId, transactionNumber) => {
  try {
    const response = await axios.put(`bank/lrs/${hashId}/transaction`, {
      transactionNumber: transactionNumber
    })
    return response.data
  } catch (error) {
    return error.response
  }
}

export const putLrsAndPickup = async (hashId, body) => {
  try {
    const response = await axios.put(`bank/lrs/${hashId}/lrsPickup`, body)
    return response.data
  } catch (error) {
    return error.response.data
  }
}

export const putAdditionalDetails = async (hashId, body) => {
  try {
    const response = await axios.put(`bank/instaPay/${hashId}/additionalDetails`, body)
    return response.data
  } catch (error) {
    return error.response.data
  }
}

export const putApplyFunds = async (fundId, accountHashId, isInstaPay,termsandcondition) => {
  try {
    const response = await axios.put(`bank/lrs/${fundId}/accountDetail`, {
      accountHashId: accountHashId,
      isInstaPay,
      conditionsAgreed:termsandcondition
    })
    return response.data
  } catch (error) {
    return error.response
  }
}
// export const getProfileData = async data => {
// 	try {
// 		const response = await axios.get(`users/account-info`)
// 		return response.data
// 	} catch (error) {
// 		console.log(error)
// 	}
// }

export const pendingOrdersData = async data => {
  try {
    const response = await axios.get(`trades/orders/current?`)
    return response.data
  } catch (error) {
    console.log(error)
  }
}

export const lastWeekOrdersData = async data => {
  try {
    const response = await axios.get(`trades/orders/history?days=30`)
    return response.data
  } catch (error) {
    console.log(error)
  }
}
/** Trade APi */

export const tradeStock = async data => {
  try {
    const response = await axios.post('trades/stock', data)
    if (response.data && response.data.code === 200) {
      return response.data;
    }
  } catch (error) {
    throw error.response
  }
}

export const tradeStack = async data => {
  try {
    const response = await axios.post('trades/stack', data)
    if (response.data && response.data.code === 200) {
      return response.data;
    }
  } catch (error) {
    throw error.response
  }
}

export const getPortfolioDetailAllocations = async (ticker, amount) => {
  try {
    const response = await axios.get(`users/portfolio/detail/${ticker}?amount=${amount}`)
    if (response.data && response.data.code === 200) {
      return response.data.data
    }
  } catch (error) {
    throw error.response
  }
}

// export const ordersData = async data => {
// 	try {
// 		const response = await axios.get(`trades/orders/current`)
// 		return response.data
// 	} catch (error) {
// 		console.log(error)
// 	}
// }

export const checkPartnerAvailability = async partnerCode => {
  try {
    const response = await axios.get(`site/check-partner/${partnerCode}`)
    return response.data
  } catch (error) {
    console.log(error)
  }
}

export const LeadCapture = async (data) => {
  try {
    const response = await axios.post('site/capture-lead', data)
    return response.data
  } catch (error) {
    console.log(error)
  }
}



export const geoLocation = async () => {
  const publicIp = require('public-ip');
  try {
    // const response = await axios.get(`site/ip`)
    const response = await axios.get(`site/country/${await publicIp.v4()}`)
    return response.data
  } catch (error) {
    console.log(error)
  }
}

export const getTradeSummary = async () => {
  try {
    const response = await axios.get(`site/trade-summary-fixed`)
    return response.data
  } catch (error) {
    return error
  }
}

export const checkLoginByPartnerToken = async data => {
    return axiosCall1('POST', '/site/check-login', data);
}

export const clientLogin = async data => {
    const headers = {
      Accept: 'application/json',
    }
    return await axiosCall1('POST','/SSO/IIFL/login', data, headers)
}

export const addInWatchlist = async data => {

  try {
    const response = await axios.post('instrument/stocks/watchlist', { code: data })
    if (response.data && response.data.code === 200) {
      return response.data.code;
    }
  } catch (error) {
    throw error.response
  }
}
export const getFilterStocks = async (url) => {
  try {
    const response = await axios.get(url)
    return response.data
  } catch (error) {
    return error
  }
}

export const getPriceRangeFilter = async () => {
  try {
    const response = await axios.get('instrument/price-range')
    return response.data
  } catch (error) {
    return error
  }
}



/*****Reports api*/
export const getPnLList = (userID) => {
  let options = {
    method: 'GET',
    url: `/users/report/pnl-statements-list`
  }
  return axios(options).then(function (response) {
    try {
      if (response.status === 200) {
        return response.data.data;
      } else {
        return {
          status: 500,
          data: 'Oops! Something went wrong.'
        }
      }
    } catch (err) {
      return {
        status: 500,
        data: 'Oops! Something went wrong.'
      }
    }
  })
}

export const getLrsStatements = (userId, startDate, endDate) => {
  return axios(
    {
      method: 'GET',
      url: `users/report/lrs?fromDate=${startDate}&toDate=${endDate}`
      // url: "/api/lrs/list/" + userId + "/" + startDate + "/" + endDate,
    })
    .then(function (response) {
      return {
        status: response.status,
        docs: response.data.data
      }
    })
    .catch(function (error) {
      return {
        status: 500,
        error: error
      }
    });
}

export const getMonthlyStatement = (userId, from, to) => {
  let options = {
    method: 'GET',
    url: `/users/report/monthly/?fromDate=${from}&toDate=${to}`
  }
  return axios(options).then(function (response) {
    try {
      if (response.status === 200) {
        return response.data;
      } else {
        return {
          status: 500,
          data: 'Oops! Something went wrong.'
        }
      }
    } catch (err) {
      return {
        status: 500,
        data: 'Oops! Something went wrong.'
      }
    }
  })
}

export const getTaxStatement = (userId, from, to) => {
  let options = {
    method: 'GET',
    url: `/users/report/tax/?fromDate=${from}&toDate=${to}`
  }
  return axios(options).then(function (response) {
    try {
      if (response.status === 200) {
        return response.data;
      } else {
        return {
          status: 500,
          data: 'Oops! Something went wrong.'
        }
      }
    } catch (err) {
      return {
        status: 500,
        data: 'Oops! Something went wrong.'
      }
    }
  })
}

export const getTradeConfirmation = (userId, from, to) => {
  let options = {
    method: 'GET',
    url: `/users/report/confirm-trade/?fromDate=${from}&toDate=${to}`
  }
  return axios(options).then(function (response) {
    try {
      if (response.status === 200) {
        return response.data;
      } else {
        return {
          status: 500,
          data: 'Oops! Something went wrong.'
        }
      }
    } catch (err) {
      return {
        status: 500,
        data: 'Oops! Something went wrong.'
      }
    }
  })
}

export const getFinancialTransaction = (userID, from, to) => {
  let options = {
    method: 'GET',
    url: `/trades/transactions/?from=${from}&to=${to}&direction=desc`
  }
  return axios(options).then(function (response) {
    try {
      if (response.status === 200) {
        return response.data;
      } else {
        return {
          status: 500,
          data: 'Oops! Something went wrong.'
        }
      }
    } catch (err) {
      return {
        status: 500,
        data: 'Oops! Something went wrong.'
      }
    }
  })
}
export const getStatementFile = (userId, fileKey) => {
  let options = {
    method: 'GET',
    url: `/users/report/file/${fileKey}`,
    responseType:'arraybuffer',
    headers:{
      Accept: 'application/pdf',
    }
  }
  return axios(options).then(function (response) {
    try {
      if (response.status === 200) {
        return response;
      } else {
        return {
          status: 500,
          data: 'Oops! Something went wrong.'
        }
      }
    } catch (err) {
      return {
        status: 500,
        data: 'Oops! Something went wrong.'
      }
    }
  })
}
export const getLrsDoc = (requestId) => {
  return axios(
    {
      method: 'get',
      // url: "http://localhost:9000/api/user/bank-lrs/generate/doc/" + requestId,
      url: `users/report/lrs/${requestId}`,
    })
    .then(function (response) {
      return {
        status: response.status,
        docs: response.data.data
      }
    })
    .catch(function (error) {
      return {
        status: 500,
        error: error
      }
    });
}

export const getInvoice = (from, to) => {
  let options = {
    method: 'GET',
    url: `users/report/invoice?fromDate=${from}&toDate=${to}`
  }
  return axios(options).then(function (response) {
    try {
      if (response.status === 200) {
        return response.data;
      } else {
        return {
          status: 500,
          data: 'Oops! Something went wrong.'
        }
      }
    } catch (err) {
      return {
        status: 500,
        data: 'Oops! Something went wrong.'
      }
    }
  })
}


export const getLrsReport = (requestId) => {
  let options = {
    method: 'GET',
    url: `users/report/lrs-report/${requestId}`,
    responseType:'arraybuffer',
    headers:{
      Accept: 'application/pdf',
    }
  }
  return axios(options).then(function (response) {
    try {
      if (response.status === 200) {
        return response;
      } else {
        return {
          status: 500,
          data: 'Oops! Something went wrong.'
        }
      }
    } catch (err) {
      return {
        status: 500,
        data: 'Oops! Something went wrong.'
      }
    }
  })
}

export const getPnLStatement = (userID, fileName) => {
  let options = {
    method: 'GET',
    url: `/users/report/gen-pnl-url?fileName=${fileName}`
  }
  return axios(options).then(function (response) {
    try {
      if (response.status === 200) {
        return response.data;
      } else {
        return {
          status: 500,
          data: 'Oops! Something went wrong.'
        }
      }
    } catch (err) {
      return {
        status: 500,
        data: 'Oops! Something went wrong.'
      }
    }
  })
}


export const getPlansByPartner = (currency, partnercode) => {
  console.log(currency,partnercode)
  return axiosCall1('GET', `/site/plans/${currency}/${partnercode}`);
}


export const checkHostByPartner = (username) => {
  return axiosCall1('GET', `/details/${username}`);
}

export const userLogin = async(data, screenDetails) => {
  let deviceData = screenDetails?screenDetails:{};
  if(navigator && navigator.userAgentData && navigator.userAgentData.getHighEntropyValues){
    try{
      deviceData.platformVersion = (await navigator.userAgentData.getHighEntropyValues(["platformVersion"])).platformVersion
    }catch(error){
      console.log('Error while parsing useragent')
    }
  }
  return axiosCall1('POST', '/login-with-user', {...data, deviceMeta:deviceData});
}

export const forgotPassword = (username) => {
  return axiosCall1('POST', '/forgot-password?web=true', { username });
}

export const forgotUsername = (nationalId, email, riaId) => {
  return axiosCall1('POST', '/forgot-username', { idNumber:nationalId, email, riaId });
}

export const validateOtp = async (hashID, otp) => {
  return axiosCall1('POST', '/validate-otp?web=true', { hashID, otp });
}


export const updatePassword = async (password, hashID, otp) => {
  return axiosCall1('PUT', '/update-password?web=true', {
    password,
    hashID,
    otp
  });
}

export const socketLogin = async (token) => {
  const url = `${process.env.REACT_APP_SOCKET_ENDPOINT}/socketAuth`
  const body = {
    authToken: token
  }
  try {
    let resp = await axios.post(url, body, {});
    if(resp.status === 200) {
      return resp.data.data;
    }
  } catch(err) {
    throw err;
  }
}

// export const userLogin = async (data) => {
//   try {
//     const response = await axios.post('/login-with-user',data)
// 		return response
// 	} catch (error) {
//     console.log(error)
// 		return error.response
// 	}
// }

export const summaryData = () => {
  return axiosCall('GET', '/users/accountSummary/summary');
}



export const customOTP = (data) => {
  return axiosCall('POST', '/send-otp', data);
}


export const getFinancialStockData = (period, code, statement, userID) => {
  let options = {
    method: 'GET',
    baseURL: process.env.REACT_APP_BASE_API,
    url: `v2/instrument/fundamentals/stocks/${code}/statements/${period}?statementCode=${statement}&amount_scaling=Y`,
  }
  return axios(options).then(function (response) {
    try {
      if (response.status === 200) {
        return response.data;
      } else {
        return {
          status: 500,
          data: 'Oops! Something went wrong.'
        }
      }
    } catch (err) {
      return {
        status: 500,
        data: 'Oops! Something went wrong.'
      }
    }
  })
}

export const getShareFundamentals = async (code) => {
  try {
    const response = await axios.get(`users/portfolio/current/${code}`)
    return response.data.data
  } catch (error) {
    return error
  }
}


export const getTrendingStocks = async () => {
  try {
    const response = await axios.get('instrument/stocks?sortBy=trending&pageSize=20&page=1')
    return response.data.data
  } catch (error) {
    console.log(error)
  }
}

export const getEtfDetails = async (code) => {
  try {
    const response = await axios.get(`instrument/fundamentals/etf/${code}/info`)
    return response.data.data
  } catch (error) {
    console.log(error)
  }
}
export const createApplicant = async data => {
  try {
    const response = await axios.post('identity/applicants', data)
    if (response.data && response.data.code === 200) {
      return response.data;
    }
  } catch (error) {
    throw error.response
  }
}

export const getSdkToken = async (applicantID) => {
  try {
    const response = await axios.post('identity/sdk_token', { applicantId: applicantID })
    if (response.data && response.data.code === 200) {
      return response.data;
    }
  } catch (error) {
    throw error.response
  }
}

export const identityInitiate = async (applicantID, document) => {
  try {
    const response = await axios.post('identity/initiate', { applicantId: applicantID, document })
    if (response.data && response.data.code === 200) {
      return response.data;
    }
  } catch (error) {
    throw error.response
  }
}

export const identityInitiateUpload = async (applicantID, document) => {
  try {
    const response = await axios.post('identity/initiate-upload', { applicantId: applicantID, document })
    if (response.data && response.data.code === 200) {
      return response.data;
    }
  } catch (error) {
    throw error.response
  }
}

export const extractDocumentContent = async (applicantID) => {
  try {
    const response = await axios.get(`identity/documents/extract?applicantId=${applicantID}`)
    if (response.data && response.data.code === 200) {
      return response.data;
    }
  } catch (error) {
    throw error.response
  }
}

export const postWatchListTicker = async (ticker) => {
  const tick = {
    body: {
      code: ticker
    }
  }
  try {
    const response = await axios.post(`instrument/stocks/watchlist`, tick.body)
    if (response.data && response.data.code === 200) {
      return response.data;
    }
  } catch (error) {
    throw error.response
  }
}

export const deleteWatchListTicker = async (ticker) => {
  try {
    const response = await axios.delete(`instrument/stocks/watchlist/${ticker}`)
    if (response.data && response.data.code === 200) {
      return response.data;
    }
  } catch (error) {
    throw error.response
  }
}

// export const refreshToken = async () => {
//   try {
//     const response = await axios.get('/v2/refresh-with-user')
//     return response.data.data
//   } catch (error) {
//     console.log(error)
//   }
// }

export const getAnalystRatings = async (code) => {
  try {
    const response = await axios.get(`instrument/stocks/${code}/analyst-ratings`)
    return response.data.data
  } catch (error) {
    console.log(error)
  }
}


export const getStocksChart = async (code, period, tag) => {
  try {
    const response = await axios.get(`instrument/fundamentals/stocks/${code}/info/by-tags?fiscalPeriodCode=${period}&tags=${tag}`)
    return response.data
  } catch (error) {
    console.log(error)
  }
}

// cancel order Api

export const cancelOrder = async (e) => {
  try {
    const response = await axios.put(`trades/orders/cancel/${e.id}`)
    return response
  } catch (error) {
    return error.response
  }
}

/** Withdraw API ====== Redemption */
export const withdrawFunds = async data => {
  try {
    const response = await axios.post('users/redemption', data)
    if (response.data && response.data.code === 200) {
      return response.data;
    }
  } catch (error) {
    throw error.response
  }
}

export const orderInAWeek = async () => {
  try {
    const response = await axios.get(`site/trade-details?days=7&tradeType=days`)
    return response
  } catch (error) {
    return error.response
  }
}

export const previousTransactionFund = async (hashId,body) => {
  try {
    const response = await axios.put(`bank/lrs/${hashId}/previous`,body)
    return response.data
  } catch (error) {
    throw error.response
  }
}
export const getStocksInfo = async (code) => {
  try {
    const response = await axios.get(`instrument/fundamentals/stocks/${code}/info`)
    return response.data.data
  } catch (error) {
    console.log(error)
  }
}

export const deleteBankAccount = async (id) => {
  try {
    const response = await axios.delete(`/bank/lrs/${id}/account`)
    if (response.data && response.data.code === 200) {
      return response.data
    } }catch (error) {
      throw error.response
    }
  }

export const getTradeCycleDates = async () => {
  try {
    const response = await axios.get('/common/market/tradeCycleDates')
    if (response.data && response.data.code === 200) {
      return response.data.data;
    }
  } catch (error) {
    throw error.response
  }
}

// themes detail
export const getThemesDetail = async (e) => {
  try {
    // const response = await axios.get(`instrument/themes/${e}`)
    const response = await axios.get(`instrument/themeDetail?themeCode=${e}`)
    return response.data.data
  } catch (error) {
    console.log(error)
  }
}

//upload document for fairexpay
export const postDocument = (hashID,data) => {
  let options = {
    method: 'POST',
    url: `bank/instaPay/${hashID}/document`,
    data:data,
    config: { headers: {'Content-Type': 'multipart/form-data' }}
  }
  return axios(options).then(function (response) {
    try {
      if (response.status === 200) {
        return response.data;
      } else {
        return {
          status: 500,
          data: 'Oops! Something went wrong.'
        }
      }
    } catch (err) {
      return {
        status: 500,
        data: 'Oops! Something went wrong.'
      }
    }
  })
}

export const getQuote = async id => {
  try {
    const response = await axios.get(`bank/instaPay/${id}/quote`);
    return response.data
  } catch (error) {
    return error.response.data
  }
}

export const fairexpayIntiateTransaction = async id => {
  try {
    const response = await axios.post(`bank/instaPay/${id}/initiateTrans`);
    return response.data
  } catch (error) {
    return error.response.data
  }
}

export const fairexpayMakePayment = async (id,data) => {
  try {
    const response = await axios.post(`bank/instaPay/${id}/payment`,data);
    return response.data
  } catch (error) {
    return error.response.data
  }
}

export const processTransaction = async id => {
  try {
    const response = await axios.post(`bank/instaPay/${id}/processPayment`);
    return response.data
  } catch (error) {
    return error.response.data
  }
}

export const getA2Form = async id => {
  try {
    const response = await axios.get(`bank/lrs/${id}/a2form`);
    return response.data
  } catch (error) {
    return error.response.data
  }
}


export const getHoldingDetails = async (code) => {
  const token = JSON.parse(sessionStorage.getItem('token'))
  const headers = {
    Accept: 'application/json',
    'Authorization':token.accessToken
  }
  try {
    const response = await axios.get(`${process.env.REACT_APP_MORNING_STAR_BASE_API}/morningstar/holding/${code}`,{headers})
    return response.data.data
  } catch (error) {
    console.log(error)
  }
}

export const getRatingsDetails = async (code) => {
  const token = JSON.parse(sessionStorage.getItem('token'))
  const headers = {
    Accept: 'application/json',
    'Authorization':token.accessToken
  }
  try {
    const response = await axios.get(`${process.env.REACT_APP_MORNING_STAR_BASE_API}/morningstar/rating/${code}`,{headers})
    return response.data.data
  } catch (error) {
    console.log(error)
  }
}

export const getSectorDetails = async (code) => {
  const token = JSON.parse(sessionStorage.getItem('token'))
  const headers = {
    Accept: 'application/json',
    'Authorization':token.accessToken
  }
  try {
    const response = await axios.get(`${process.env.REACT_APP_MORNING_STAR_BASE_API}/morningstar/sector/${code}`,{headers})
    return response.data.data
  } catch (error) {
    console.log(error)
  }
}

export const getAssetDetails = async (code) => {
  const token = JSON.parse(sessionStorage.getItem('token'))
  const headers = {
    Accept: 'application/json',
    'Authorization':token.accessToken
  }
  try {
    const response = await axios.get(`${process.env.REACT_APP_MORNING_STAR_BASE_API}/morningstar/asset/${code}`,{headers})
    return response.data.data
  } catch (error) {
    console.log(error)
  }
}

export const getIndustryDetails = async (code) => {
  const token = JSON.parse(sessionStorage.getItem('token'))
  const headers = {
    Accept: 'application/json',
    'Authorization':token.accessToken
  }
  try {
    const response = await axios.get(`${process.env.REACT_APP_MORNING_STAR_BASE_API}/morningstar/industry/${code}`,{headers})
      return response.data.data
  } catch (error) {
    console.log(error)
  }
}

// profile Update Api

export const updateProfile = async (update) => {
  console.log('update',update)
  try {
    const response = await axios.post(`users/update?source=web`,update.body)
    return response
  } catch (error) {
    throw error
  }
}

// profile Validate password

export const validatePassword = async (update) => {
  try {
    const response = await axios.post(`/validate-password`,update.body)
    return response
  } catch (error) {
    throw error.response.data
  }
}

//Beneficiary/Nominee
export const getNomineeList = async () => {
  const token = JSON.parse(sessionStorage.getItem('token'))
  const headers = {
    Accept: 'application/json',
    'Authorization':token.accessToken
  }
  try {
    const response = await axios.get('/beneficiary', {headers})
    return response;
  } catch (error) {
    throw error.response
  }
}
export const postNomineeList = async (data) => {
  const token = JSON.parse(sessionStorage.getItem('token'))
  const headers = {
    'Authorization':token.accessToken
  }
  try {
    const response = await axios.post('/beneficiary', data, {headers})
    return response;
  } catch (error) {
    throw error.response
  }
}
export const getToken = async (code,otp='') => {
  try {
    const data = otp === '' ? {
      auth_code:code
    } : {
      auth_code:code,
      otp
    }
    const response = await axios.post(`/partnersso/token`,data)
    return response.data
  } catch (error) {
   throw error.response
  }
}

export const resendotpsso = async (auth_code) => {
  try {
    const response = await axios.post(`/partnersso/resend-otp`,{auth_code})
    return response
  } catch (error) {
    console.log(error)
   throw error.response
  }
}

export const ssoLogin = async (data) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_AUTH}/oauth/login`,data)
    return response
  } catch (error) {
   throw error.response
  }
}

export const getAnnualPnlStatement = async (type,year,period,userId)=>{
  const urlupdate = type === 'annual' ? `users/report/pnl-report?type=${type}&endYear=${year}&period=${period}` :
                  `users/report/pnl-report?type=${type}&endYear=${year}&month=${period}`
  let options = {
    method: 'GET',
    url: urlupdate,
    responseType: "blob",
    headers:{
      Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
  }
  return axios(options).then(function (response) {
    try {
      if (response.status === 200) {
        return response;
      }
    } catch (error) {
      throw error.response
    }
  })
}

// disclosures

export const getExtendedHourDisclosure = async pincode => {
  try {
    const response = await axios.get(`/disclosure/extendedHours`)
    return response.data
  } catch (error) {
    throw error.response
  }
}

export const getAccountClosureDisclosure = () => {
  return axiosCall1('GET', `/disclosure/account_closure`);
}

export const accountDeactivation = () => {
  return axiosCall1('GET', `/users/deactivate`);
}

// PLANS COMPONENT ********************************

// export const getPlanInfo = async data => {
//   try {
//     const response = await axios.get(`/subscription/plans/payment/details`)
//     return response.data
//   } catch (error) {
//     console.log(error)
//   }
// }

export const getPlans = (currency, pageType) => {
  return axiosCall1('GET', `/subscription/plans/list/${currency}/${pageType}`);
}
// export const validateCoupon = (coupon, planId) => {
//   return axiosCall1('GET', `/subscription/plans/coupon/${coupon}/${planId}`);
// }

export const generateOrderId = (data) => {
  return axiosCall1('POST', '/subscription/plans/generate-orderId', data);
}

export const savePayment = (data) => {
  return axiosCall1('POST', '/subscription/plans/payment/subscription', data);
}

export const upgradePayment = (data) => {
  return axiosCall1('POST', '/subscription/plans/payment/upgrade-subscription', data);
}

// export const checkPaymentStatus = (paymentId) => {
//   return axiosCall('GET', `/subscription/plans/payment/status/${paymentId}`);
// }

export const currentPlan = () => {
  return axiosCall1('GET', '/subscription/plans/payment/details');
}

export const checkCouponDiscount = (coupon, planId) => {
  return axiosCall1('GET', `/subscription/plans/coupon-discount/${coupon}/${planId}`);
}

export const redeemCoupon = async (coupon)=>{
  return axiosCall1('GET', `/subscription/plans/redeem-coupon/${coupon}`)
}

export const upgradePlanPrice = (newPlanId, amount) => {
return axiosCall1('GET', `/subscription/plans/upgrade-plan-price/${newPlanId}/${amount}`)
}

//End PLANS COMPONENT ********************************
export const lrsPDF = async (bank) =>{
  let options = {
    method: 'GET',
    url: `/bank/lrs/instructions/${bank}`,
    responseType:'arraybuffer',
    headers:{
      Accept: 'application/pdf',
    }
  }
  return axios(options).then(function (response) {
    try {
      if (response.status === 200) {
        return response;
      } else {
        return {
          status: 500,
          data: 'Oops! Something went wrong.'
        }
      }
    } catch (err) {
      return {
        status: 500,
        data: 'Oops! Something went wrong.'
      }
    }
  })
}


// PAN APIs
export const getPANDetails = () => {
  return axiosCall1('GET', '/users/pan-details')
}

export const verifyAndSavePAN = (data) => {
  return axiosCall1('POST', '/common/pan/verify', data);
}

// cashManagement APIs
export const getCashManagementTransactionData = async(fromDate,toDate,accountId,type,pagecount,download) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_CASH_MANAGEMENT}/transaction/list/${accountId}?from=${fromDate}&to=${toDate}&status=${type}${pagecount === undefined ?  "" : `&currentPage=${pagecount}`}&download=${download}`)
    return response.data
  } catch (error) {
    return error
  }
}

export const getAllTransactions = async(fromDate,toDate,accountId) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_CASH_MANAGEMENT}/transaction/list/${accountId}?from=${fromDate}&to=${toDate}`)
    return response.data
  } catch (error) {
    return error
  }
}


export const getCashManagementData = async() => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_CASH_MANAGEMENT}/bank-account/`)
    return response.data
  } catch (error) {
    return error
  }
}

export const getCardDetails = async() => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_CASH_MANAGEMENT}/bank-account/cards/list-cards`)
    return response.data
  } catch (error) {
    return error
  }
}

export const getTransactionDetails = async(stoTranId) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_CASH_MANAGEMENT}/transaction?stoTranId=${stoTranId}`)
    return response.data
  } catch (error) {
    return error
  }
}

export const getContactListTransferDetails = async(accountId) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_CASH_MANAGEMENT}/bank-account/contact?accountId=${accountId}`)
    return response.data
  } catch (error) {
    return error
  }
}

export const getTransferContactDetails = async(stoCashPayeeId) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_CASH_MANAGEMENT}/bank-account/contact/${stoCashPayeeId}`)
    return response.data
  } catch (error) {
    return error
  }
}

export const onBoardingValidateOtp = async (hashID, otp) => {
  try {
    const response = await axios.post('onboarding/verify-otp', { hashID, otp })
    return response
  } catch (error) {
    if(error && error.response){
     throw error.response.data
    }else{
      throw new Error('Internet Connection lost')
    }
  }
}

export const onAccountDetected = async (email) => {
  try {
    const response = await axios.post('forgot-username', {email })
    return response.data
  } catch (error) {
    console.log(error)
   throw error.response.data
  }
}

export const createUser = async (data,screenDetails) => {
  let deviceData = screenDetails?screenDetails:{};
  if(navigator && navigator.userAgentData && navigator.userAgentData.getHighEntropyValues){
    try{
      deviceData.platformVersion = (await navigator.userAgentData.getHighEntropyValues(["platformVersion"])).platformVersion
    }catch(error){
      console.log('Error while parsing useragent')
    }
  }
  try {
    // const response = await axios.post(`onboarding/create-user`, {...data, deviceMeta:deviceData})
    const response = await axios.post(`onboarding/user/create-account`, {...data, deviceMeta:deviceData})
    return response.data
  } catch (error) {
    if(error && error.response && error.response.data){
    throw error.response.data
    }
    else{
      throw new Error('Internet Connection lost')
    }
  }
}

export const getContactBankDetails = async(number,type) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_CASH_MANAGEMENT}/bank-account/address?routingNumber=${number}&type=${type}`)
    return response.data
  } catch (error) {
    return error.response
  }
}

export const createContact = async (data) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_CASH_MANAGEMENT}/bank-account/contact`,data.body)
    return response
  } catch (error) {
    console.log(error)
   throw error.response
  }
}

export const transferMoney = async (data) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_CASH_MANAGEMENT}/transaction/send`,data.body)
    return response
  } catch (error) {
    console.log(error)
   throw error
  }
}

export const transferingMoneyToContact = async (data) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_CASH_MANAGEMENT}/transaction/send`,data.body)
    return response
  } catch (error) {
    console.log(error)
   throw error.response
  }
}

export const transactionCharges = async (transferType,CountryCode) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_CASH_MANAGEMENT}/transaction/fee?transferType=${transferType}&countryCode=${CountryCode}`)
    return response
  } catch (error) {
    console.log(error)
   throw error.response
  }
}

export const getShowToken = async (cardId) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_CASH_MANAGEMENT}/bank-account/cards/create-token?cardId=${cardId}&type=show`)
    return response.data
  } catch (error) {
    console.log(error)
  }
}

export const freezeVirtualCard = async(data) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_CASH_MANAGEMENT}/bank-account/cards/freeze-card`,data)
    return response
  } catch (error) {
   throw error.response
  }
}

export const unfreezeVirtualCard = async(data) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_CASH_MANAGEMENT}/bank-account/cards/unfreeze-card`,data)
    return response
  } catch (error) {
   throw error.response
  }
}

export const validateTheOtp = async(data) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_BASE_API}/v2/common/otp/validate-otp`,data)
    return response
  } catch (error) {
   throw error.response
  }
}

export const createPinToken = async(cardId) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_CASH_MANAGEMENT}/bank-account/cards/create-token?cardId=${cardId}&type=pin`)
    return response
  } catch (error) {
   throw error.response
  }
}

export const activateCard = async(data) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_CASH_MANAGEMENT}/bank-account/cards/activate`,data)
    return response
  } catch (error) {
    throw console.response
  }
}

//Get PAN Number
export const getPAN = async () => {
  try {
    const response = await axios.get('users/pan-details')
    return response
  } catch (error) {
    console.log(error)
   throw error.response
  }
}
//verify PAN
export const verifyPAN = async(payload,source)=>{
  try {
    let url = 'common/pan/verify'
    if(source){
      url =`common/pan/verify?source=${source}`
    }
    const response = await axios.post(url,payload)
    return response
  } catch (error) {
   return error.response
  }
}

//ifsc address

export const fetchIFSCDetail = async(code)=>{
  try {
    const response = await axios.get(`bank/ifsc/${code}`)
    return response
  } catch (error) {
    console.log(error)
   throw error.response
  }
}

//cash management details

export const fetchSavingAccount = async()=>{
  try {
    const response = await axios.get(`bank/saving-account`)
    return response
  } catch (error) {
   throw error.response
  }
}

//HDFC OTP request
export const requestOTP = async (accountId) => {
	try {
		const response = await axios.post(`HDFC/otpRequest`, {accountId})
		return response
	} catch (error) {
		throw error.response
	}
}

//HDFC Transaction details
export const HDFCTransDetails = async (fund) => {
	try {
		const response = await axios.get(`HDFC/fxrate?source=INR&destination=USD&amount=${fund}`)
		return response
	} catch (error) {
		throw error.response
	}
}

//HDFC OTP verification
export const HDFCVerifyOTP = async (payload) => {
	try {
		const response = await axios.post(`HDFC/otpVerification`, payload)
		return response
	} catch (error) {
		throw error.response
	}
}

//HDFC upload statement
export const HDFCPostStatement = (data) => {
  let options = {
    method: 'POST',
    url: `HDFC/uploadStatement`,
    data:data,
    config: { headers: {'Content-Type': 'multipart/form-data' }}
  }
  return axios(options).then(function (response) {
      return response
    }).catch (function (error) {
      return error.response
    })
}

export const onBoardingUpdateUser = async (payload) => {
	try {
		const response = await axios.post(`onboarding/update-user`, payload)
		return response
	} catch (error) {
		throw error.response.data
	}
}

export const onBoardingUserScope = async (custId) => {
	try {
		const response = await axios.get(`onboarding/user-scope?custId=${custId}`)
		return response.data
	} catch (error) {
		throw error.response.data
	}
}

// funds Redesign
export const getFundsTransactionData = async(customerId,status,fromDate,toDate,pagecount,download) => {
  try {
    // const response = await axios.get(`${process.env.REACT_APP_EASYFUND}/fund/customer/transaction/get?customerId=${customerId}&status=${status}&from=${fromDate}&to=${toDate}${pagecount === undefined ?  "" : `&currentPage=${pagecount}`}&download=${download}`)
    const response = await axios.get(`${process.env.REACT_APP_EASYFUND}/fund/customer/transaction/all-funds?customerId=${customerId}&status=${status}&from=${fromDate}&to=${toDate}${pagecount === undefined ?  "" : `&currentPage=${pagecount}`}&download=${download}`)
    return response.data
  } catch (error) {
    return error
  }
}

export const getFundsTransactionDetails = async(transId) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_EASYFUND}/fund/customer/transaction/${transId}`)
    return response.data
  } catch (error) {
    return error
  }
}


// easy fund
export const getQuoteEasyfund = async (amount) => {
	try {
		const response = await axios.get(`${process.env.REACT_APP_EASYFUND}/fund/customer/lrs/quote/get?amount=${amount}`)
		return response.data
	} catch (error) {
		throw error.response.data
	}
}

export const getKycStatus = async (vendorName) => {
	try {
		const response = await axios.get(`${process.env.REACT_APP_EASYFUND}/fund/customer/lrs/kycstatus?vendorName=${vendorName}`)
		return response.data
	} catch (error) {
		throw error.response.data
	}
}

export const uploadBankStatement = async (transactionId,file,payload,password) => {
	try {
    const splitType = file && file.type ? file.type === '.pdf' ? 'pdf': file.type.split('/')[1].trim() : ''
		const response = await axios.post(`${process.env.REACT_APP_EASYFUND}/fund/customer/transaction/upload-doc?transactionId=${transactionId}&fileType=${splitType}&fileName=${file.path}&docPassword=${password}`, payload)
    return response.data
	} catch (error) {
		throw error.response.data
	}
}

export const getPaymentLinkEasyFund = async (payload) => {
	try {
		const response = await axios.post(`${process.env.REACT_APP_EASYFUND}/fund/customer/paymentlink/get`, payload)
		return response.data
	} catch (error) {
		throw error.response.data
	}
}

export const intiateTransactionEasyfund = async (payload) => {
	try {
		const response = await axios.post(`${process.env.REACT_APP_EASYFUND}/fund/customer/transaction/submit`, payload)
		return response.data
	} catch (error) {
		throw error.response.data
	}
}
export const getTransactionStatus = async (transactionId) => {
	try {
		const response = await axios.get(`${process.env.REACT_APP_EASYFUND}/fund/customer/transaction/${transactionId}`)
		return response.data
	} catch (error) {
		throw error.response.data
	}
}

export const verifyAdharStatus = async (payload,transactionId) => {
	try {
		const response = await axios.post(`${process.env.REACT_APP_EASYFUND}/fund/customer/lrs/upload-doc?transactionId=${transactionId}`, payload)
    return response.data
	} catch (error) {
		throw error.response.data
	}
}

export const getTransBankDetail = async (transactionId) => {
	try {
		const response = await axios.get(`${process.env.REACT_APP_EASYFUND}/fund/customer/transaction-bank-details`,{params:{stoTransactionId:transactionId}})
    return response.data
	} catch (error) {
		throw error.response.data
	}
}

export const createConsent = async (payload) => {
	try {
		const response = await axios.post(`${process.env.REACT_APP_EASYFUND}/fund/customer/aa/consent`, payload)
    return response.data
	} catch (error) {
		throw error.response.data
	}
}

export const getConsentStatus = async (bankId) => {
	try {
		const response = await axios.get(`${process.env.REACT_APP_EASYFUND}/fund/customer/aa/consent/${bankId}`)
		return response.data
	} catch (error) {
		throw error.response.data
	}
}

export const getDigilockerUrl = async (source) => {
	try {
    let url = `${process.env.REACT_APP_EASYFUND}/onboarding/digilocker/url`
    if(source){
      url = `${process.env.REACT_APP_EASYFUND}/onboarding/digilocker/url?source=${source}`
    }
		const response = await axios.get(url)
		return response.data
	} catch (error) {
		throw error.response.data
	}
}

// Security Lending call
export const fpslChange = (agreement, otp, hashId) =>{
  const data={fpslAgreement:agreement}
  if(agreement){
    data.otp=otp
    data.hashId=hashId
  }
  return axiosCall1('POST', '/users/fpsl', {...data})
}
export const fpslAgreeSiteNotification = () =>{
  return axiosCall1('POST', '/users/fpsl', {fpslAgreement: true,isExistingUser: true})
}
export const postSiteNotification = (status) =>{
  return axiosCall1('POST', '/users/site-notification',{status})
}
export const getSiteNotification = (custId) =>{
  return axiosCall1('GET', `/users/site-notification?custId=${custId}`)
}
export const subscribeSiteNotification = (custId,notificationId,signature) => {
  const headers = {
    "custId":custId,
    "notificationID": notificationId,
    "signature": signature
  }
  return axiosCall1('POST', '/users/site-notification', headers)
}

export const getSitePopupNotification = () =>{
  return axiosCall1('GET', '/users/site-popup-notification')
}

export const postSitePopupNotification = (status) =>{
  return axiosCall1('POST', '/users/site-popup-notification',{status})
}

export const swiftMailFunds = async (transactionId) => {
  const headers = {
  	'transactionId': transactionId,
  };
	try {
		const response = await axios.post(`${process.env.REACT_APP_EASYFUND}/fund/customer/transaction/swift-copy`, headers)
    return response.data
	} catch (error) {
		throw error.response.data
	}
}


export const identityDocIndian = async (applicantID, document) => {
  try {
    const response = await axios.post('identity/doc-initiate', { applicantId: applicantID, document })
    if (response.data && response.data.code === 200) {
      return response.data;
    }
  } catch (error) {
    throw error.response
  }
}

export const getonBoardingKycStatus = async () => {
	try {
		const response = await axios.get(`${process.env.REACT_APP_EASYFUND}/onboarding/digilocker/kyc-status`)
		return response.data
	} catch (error) {
		throw error.response.data
	}
}
export const getDigiLockerHealth = async () => {
	try {
		const response = await axios.get(`${process.env.REACT_APP_EASYFUND}/onboarding/digilocker/_health`)
		return response.data
	} catch (error) {
		throw error.response.data
	}
}
export const getShariahComplianceRating = async(code) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_BASE_API}/v2/shariah-compliance/${code}`)
    if(response.data && response.data.code === 200) {
      return response.data
    }
  } catch (error) {
      console.log(error)
  }
}

export const getPopularHalalStocks = async() => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_BASE_API}/v2/shariah-compliance/popular-halal`)
    if(response && response.status === 200) {
      return response.data
    }
  } catch (error) {
      console.log(error)
  }
}

export const getPartnerData = async(partnerCode) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_BASE_API}/v2/common/partner-data/${partnerCode}`)
    return response.data
  } catch (error) {
    console.log(error)
  }
}

export const addToCashManagementWaitlist = async(data) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_BASE_API}/v2/site/subscription`,data)
    if(response && response.status === 200) {
      return response.data
    }
  } catch (error) {
    console.log(error)
  }
}

export const checkCashMgmtWaitlist = async(data) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_BASE_API}/v2/site/check-subscription`,data)
    if(response && response.status === 200) {
      return response.data
    }
  } catch (error) {
    console.log(error)
  }
}


export const uploadDocumentApi = async(data) => {
    try {
      const response = await axios.post(`onboarding/user/upload-document`, data)
      return response.data
    } catch (error) {
      throw error.response.data
    }
}

// ACAT

export const dtccClearingList = async() => {
  try {
    const response = await axios.get(`/users/acat/dtcc-list`)
    return response.data
  } catch (error) {
    console.log(error)
  }
}

export const acatTransactionSubmit = async(data) => {
  try {
    const response = await axios.post(`/users/acat`, data)
    return response.data
  } catch (error) {
    throw error.response.data
  }
}

export const getAcatStatus = async() => {
  try {
    const response = await axios.get(`users/acat/list`)
    return response.data
  } catch (error) {
    console.log(error)
  }
}

// ACAT END

// Fund Transfer New Api's

export const getAddFundConfig = async() => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_EASYFUND}/fund/customer/config`)
    return response.data
  } catch (error) {
    console.log(error)
    throw error
  }
}

export const getConfigDataOfSelectedTransferOption = async(option) => {
  try{
    const response = await axios.get(`${process.env.REACT_APP_EASYFUND}/fund/customer/config/steps/${option}`)
    return response.data
  } catch (error) {
    console.log(error)
    throw error
  }
}

export const AxisGeneratePayment = async(data) => {
  try{
    const response = await axios.post(`${process.env.REACT_APP_EASYFUND}/fund/customer/transaction/generate-payment`,data)
    console.log('resp',response);
    return response.data
  } catch (error) {
    console.log('error',error)
    throw error
  }
}
