import React from 'react'
import { Row, Col } from 'react-bootstrap'
import { withRouter } from 'react-router-dom'
import SignUpInput from '../../../SignUp/SignUpInput'
import SignInputField from '../../../SignInputField/SignInputField'
import { updatedAccount } from '../../../../api'
import CheckBox from '../../../CheckBox/CheckBox'
import styles from '../../AccountSetUp.module.css'
import { ThemeContext } from '../../../../Context/ThemeContext'
class LegalDetail extends React.Component {
	constructor(props) {
		super(props)
		const {
			formData: { legalDetail }
		} = this.props

		const userInformation = sessionStorage.getItem('userInfo') ? JSON.parse(sessionStorage.getItem('userInfo')) : ''

		this.state = {
			showCompany: legalDetail.showCompany ? legalDetail.showCompany : userInformation && userInformation.director === "true" ? true : false,
			brokerFirmCompany: legalDetail.brokerFirmCompany ? legalDetail.brokerFirmCompany : userInformation && userInformation.employerIsBroker === "true" ? true : false,
			isDirectorChecked: legalDetail.isDirectorChecked ? legalDetail.isDirectorChecked : userInformation && userInformation.director === "true" ? true : false,
			isBrokerChecked: legalDetail.isBrokerChecked ? legalDetail.isBrokerChecked : userInformation && userInformation.employerIsBroker === "true" ? true : false,
      		isUsTaxPayerChecked: legalDetail.isUsTaxPayerChecked ? legalDetail.isUsTaxPayerChecked : userInformation && userInformation.usTaxPayer ? userInformation.usTaxPayer : false,
			companyName: legalDetail.companyName ? legalDetail.companyName : userInformation && userInformation.directorOf ? userInformation.directorOf : '',
      		employerCompany : legalDetail.employerCompany ? legalDetail.employerCompany : userInformation && userInformation.employerCompany ? userInformation.employerCompany : '',
			signature: legalDetail.signature ? legalDetail.signature : userInformation && userInformation.signedBy ? userInformation.signedBy : '',
      		errorMessage: '',
      		errorMessageType:''
		}
	}

	static contextType = ThemeContext

	componentDidMount() {
		this.props.onRef(this)
	}

	componentWillUnmount() {
		this.props.onRef(null)
	}

  updateParentState=(updatedState)=>{
    const legalDetail = Object.assign({},updatedState)
    delete legalDetail.errorMessage;
    delete legalDetail.errorMessageType;
   this.props.setFormPreData({ legalDetail})
  }

	onChangeHandler = event => {
    let obj = {};
    if(event.target.name === 'politicalPersonValue'){
      obj = {[event.target.name]: (event.target.value).replace(/[^A-Za-z ]/ig, '').trim(), errorMessage: ''}
      // this.setState(obj)
    }
    else{
      obj = { [event.target.name]: event.target.value, errorMessage: '' }
      // this.setState(obj)
    }

    const updatedState = Object.assign(this.state,obj)
    this.setState(obj,this.updateParentState(updatedState))
	}


	onShowCompanyInput = event => {
    let obj = {
			[event.target.name]: !this.state[event.target.name],
			errorMessage: ''
    }
    // this.setState(obj)
		if (event.target.name === 'isDirectorChecked') {
      obj = Object.assign(obj,{isDirectorChecked:!this.state.isDirectorChecked, showCompany: !this.state.showCompany })
			// this.setState(obj)
		}
		else if (event.target.name === 'isBrokerChecked') {
      obj = Object.assign(obj,{isBrokerChecked:!this.state.isBrokerChecked, brokerFirmCompany: !this.state.brokerFirmCompany })
			// this.setState(obj)
		}
    else if (event.target.name === 'isUsTaxPayerChecked') {
      obj = Object.assign(obj,{ isUsTaxPayerChecked:!this.state.isUsTaxPayerChecked })
			// this.setState(obj)
    }

    const updatedState = Object.assign(this.state,obj)
    this.setState(obj,this.updateParentState(updatedState))

	}

	onFormSubmit = () => {
		this.setState({ errorMessage: '' })
		const {
			signature,
			showCompany,
			companyName,
      employerCompany,
      isBrokerChecked,
      isUsTaxPayerChecked,
			isDirectorChecked
		} = this.state
		const legalDetail = {
			signature,
			showCompany,
			companyName,
      employerCompany,
      isBrokerChecked,
      isUsTaxPayerChecked,
			isDirectorChecked,
		}
		if (isDirectorChecked) {
			if (companyName === '') {
				this.setState({ errorMessage: 'Please enter Company Name',errorMessageType:'company' })
				return false
			}
		}
    if(isBrokerChecked){
      if (employerCompany === '') {
				this.setState({ errorMessage: 'Please enter Company Name',errorMessageType:'employerCompany' })
				return false
			}
    }
    return true;
		// this.props.nextForm({ legalDetail })
	}

	render() {
		const {
			signature,
			companyName,
			showCompany,
      employerCompany,
			isDirectorChecked,
      isBrokerChecked,
      isUsTaxPayerChecked,
			brokerFirmCompany,
			showRiskModal
		} = this.state
		const {
			disclosuresLink,
			termsAndConditionsLink,
			privacyPolicyLink,
			userAgreementLink
		} = this.context
		let updatedDisclosuresLink = disclosuresLink
		if (disclosuresLink === '') {
			  updatedDisclosuresLink = `https://apps.drivewealth.com/disclosures/?citizenship=${this.props.formData.personalDetail.citizenShip}&country=${this.props.formData.addressDetail.country}&accountType=Individual&extendedHours=true`
		}

		return (
			<>
				<Row className={styles.blackText}>
						Why do we need this?
				</Row>
        <Row className={styles.detail}>We need some legal details to create your account with our partners. These are essential to complete your account setup.</Row>
				<Row className={showCompany ? styles.legalBorderBox : styles.legalBox} style={{marginTop:'32px'}}>
          <Col
            className={styles.mobileMargin} md={7}>
					<CheckBox
            tabIndex='-1'
						label='I am a Director/Officer of a Public Company'
						id='directorCheckBox'
						name='isDirectorChecked'
						value={isDirectorChecked}
						onChange={this.onShowCompanyInput}
						isChecked={isDirectorChecked}
					/>
        {showCompany ? (
          <>
          <div style={{paddingLeft:'40px',paddingTop:'12px'}}>
            <SignUpInput
                  label='Name of the Company'
                  placeholder = 'Enter Company’s Name'
                  type='text'
                  name='companyName'
                  value={companyName}
                  onChange={this.onChangeHandler}
                />
          {this.state.errorMessageType === 'company'?
						<span className={styles.errorMessage}>
							{this.state.errorMessage}
						</span>:''}
            </div>
          </>
				) : (
					''
				)}
         </Col>
				</Row>
				{/* <Row> */}
				{/* {showCompany ? (
					<Row className={styles.companyInput}>
						<Col md={7} className='pl-0'>
            <SignUpInput
                  label='Name of the Company'
                  placeholder = 'Enter Company’s Name'
                  type='text'
                  name='companyName'
                  value={companyName}
                  onChange={this.onChangeHandler}
                />
            {this.state.errorMessageType === 'company'?
						<span className={styles.errorMessage}>
							{this.state.errorMessage}
						</span>:''}
						</Col>
						<Col></Col>
					</Row>
				) : (
					''
				)} */}
				{/* </Row> */}
				<Row className={brokerFirmCompany ? styles.legalBorderBox : styles.legalBox}>
        <Col
            className={styles.mobileMargin} md={7}>
					<CheckBox
            tabIndex='-1'
						label='I am employed by a Brokerage Firm'
						id='brokerCheckBox'
						name='isBrokerChecked'
						value={isBrokerChecked}
						onChange={this.onShowCompanyInput}
						isChecked={isBrokerChecked}
					/>
          {brokerFirmCompany ? (
              <>
              <div style={{paddingLeft:'40px',paddingTop:'12px'}}>
                <SignUpInput
                      label='Name of the Company'
                      placeholder = 'Enter Company’s Name'
                      type='text'
                      name='employerCompany'
                      value={employerCompany}
                      onChange={this.onChangeHandler}
                    />
              {this.state.errorMessageType === 'employerCompany'?
                <span className={styles.errorMessage}>
                  {this.state.errorMessage}
                </span>:''}
                </div>
              </>
				) : (
					''
				)}
        </Col>
				</Row>

        <Row className={styles.legalBox} style={{border:'0px'}}>
        <Col
            className={styles.mobileMargin}>
					<CheckBox
            tabIndex='-1'
						label='I am a taxpayer in the US'
						id='usTaxPayerCheckBox'
						name='isUsTaxPayerChecked'
						value={isUsTaxPayerChecked}
						onChange={this.onShowCompanyInput}
						isChecked={isUsTaxPayerChecked}
					/>
        </Col>
				</Row>
				{/* <Row className={styles.personalInputRow}>
					<Col className={styles.colPaddningMobile} md={6} xl={6} xs={12}>
						<SignInputField
							placeholder='Your Signature'
							type='text'
							className={styles.input}
							name='signature'
							value={signature}
							onChange={this.onChangeHandler}
						/>
            {this.state.errorMessageType === 'signature'?
						<span className={styles.errorMessage}>
							{this.state.errorMessage}
						</span>:''}
            <span className={styles.errorMessage}>
            {this.props.errors.errorMessage}
						</span>
					</Col>
					<Col></Col>
				</Row> */}
				{/* <Row className={styles.detail}>
					<p className='mb-0'>
						By providing my signature, I have read and understood and agree to
						the
						<a href={updatedDisclosuresLink} target='_blank' rel="noopener noreferrer">
							<span className={styles.disclosures}> Disclosures,</span>
						</a>
					</p>
					<p>
						Borderless Investing{' '}
						<span>
							<a href={userAgreementLink} target='_blank' rel="noopener noreferrer" className={styles.disclosures}>
								Terms and conditions
							</a>
							{' '}
							&{' '}
							<a href={privacyPolicyLink} target='_blank' rel="noopener noreferrer" className={styles.disclosures}>
								Privacy Policy
							</a>
						</span>
					</p>
					<p>
					By providing my signature, I authorize my broker and/or Clearing
						Firm to... <a href="https://stockal.freshdesk.com/support/solutions/articles/43000690721-broker-disclaimer" target="_blank" rel="noopener noreferrer">	<span className={styles.disclosures}>Read Details</span></a>
            </p>
				</Row> */}
			</>
		)
	}
}

export default withRouter(LegalDetail)

