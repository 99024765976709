import React,{useEffect,useState} from 'react'
import styles from './morningStar.module.css'
import {StockPieChart} from '../../components'

const MorningstarPieChart = ({sectorData}) =>{
  const [pieWidth, setPieWidth] = useState()
  const [pieChartData,setPieChartData] = useState([])

  useEffect(()=>{
    if(window.screen.width <= 767){
      setPieWidth('400')
    } else if((window.screen.width > 991) && (window.screen.width < 1200)){
      setPieWidth('400')
    } else {
      setPieWidth('250')
    }
  },[window.screen.width])

  useEffect(()=>{
    sectorData && setPieChartData(sectorData.slice(0,9).map(a => ({name:a.sector,y:a.weightagepct})))
  },[sectorData])



  return(
    <>
      <div className={styles.sector}>
        Sector Allocation
      </div>
      <StockPieChart
        width='300'
        height={pieWidth}
        data={pieChartData.slice(0,5)}
        fromEtf={true}
      >
      </StockPieChart>
    </>
  )
}

export default MorningstarPieChart
