import React, { useState } from 'react'
import { Row, Col, Table, Tabs, Tab} from 'react-bootstrap'
import styles from './etfdetail.module.css'
import CustomModal from '../../components/CustomModal/CustomModal'
import { addCommasinPrice, decimalAdjust, parseNumericMillion,parseNumericBillion } from '../../utils'
import { format } from 'date-fns'

const historicalDataType=[
      { 
        label:'Total Volume',
        keys:['TotalVolume1Week','TotalVolumeLast2Weeks','TotalVolumeLast4Weeks', 'TotalVolumeLast3Months', 'TotalVolumeLast6Months', 'TotalVolumeLast12Months','TotalVolumeLast52Weeks'],
      },
      {
        label:'Price % Change',
        keys:['PercentPriceChange1Week','PercentPriceChange2Weeks','PercentPriceChange4Weeks', 'PercentPriceChange3Months', 'PercentPriceChange6Months', 'PercentPriceChange12Months','PercentPriceChange52Weeks'],

      },
      {
        label:'Low Price',
        keys:['LowPrice1Week','LowPriceLast2Weeks','LowPriceLast4Weeks', 'LowPriceLast3Months', 'LowPriceLast6Months', 'LowPriceLast12Months','LowPriceLast52Weeks'],
      }
]
const historicalTimeFrame=['1 Week', '2 Week', '4 Week', '3 Month', '6 Month', '12 Month', '52 Week']
    
const profileType=[
  {
    label: "Market Cap",
    key: "MarketCapitalization",
    format: "dollar"
  },
  {
    label: "Outstanding Shares",
    key: "SharesOutstanding"
  },
  {
    label: "Last Volume",
    key: "LastVolume"
  },
  {
    label: "Country",
    key: "Country"
  },
  {
    label: "Dividend Yield (Daily)",
    key: "DividendYieldDaily",
    format: "dollar"
  },
  {
    label: "Dividend Yield (Fiscal)",
    key: "DividendYieldFiscal",
    format: "dollar"
  },
  {
    label: "P/B Ratio",
    key: "PriceToBook"
  },
  {
    label: "P/E Ratio",
    key: "PERatio"
  }, 
  {
    label: "Total Enterprise Value",
    key: "TotalEnterpriseValue"
  },
  {
    label: "High Price (YTD)",
    key: "HighPriceYTD",
    format: "dollar"
  },
  {
    label: "Low Price (YTD)",
    key: "LowPriceYTD",
    format: "dollar"
  },
  {
    label: "Total Volume (YTD)",
    key: "TotalVolumeYTD"
  },
  {
    label: "Date Of 52w High",
    key: "DateOf52WeekHigh",
    format: "date"
  },
  {
    label: "Date Of 52w Low",
    key: "DateOf52WeekLow",
    format: "date"
  },
  {
    label: "Last Trade Date",
    key: "LastTradeDate",
    format: "date"
  },
  {
    label: "Beta",
    key: "Beta"
  },
 {
    label: "Beta (12 Month)",
    key: "Beta12Month"
  },
  {
    label: "Beta (24 Month)",
    key: "Beta24Month"
  },
  {
    label: "Capital Gains",
    key: "CapitalGains",
    format: "dollar"
  }
]
const StatsModal = ({etfData, showModal, setShowModal}) => {
  const [activeTab, setActiveTab] = useState('profile');
  const handleClose = () => setShowModal(false);

  const showHistoricalData=(data)=>{
    let tableData=[];
    if(Object.keys(data).length>0){
      historicalTimeFrame.forEach((timeFrame, i)=>{
        let historicalTableRowData=[<td>{timeFrame}</td>]
        for(let j=0; j<historicalDataType.length; j++){
          let value=data[historicalDataType[j].keys[i]];
          let formattedValue=null;
          if(!value){
            formattedValue='--'
          }
          else if(isNaN(value)){
            formattedValue = value
          }
          else{
            if(value<=10000)
              formattedValue = addCommasinPrice(decimalAdjust('floor', value, -2));
            else if(value < 1000000000)
              formattedValue = addCommasinPrice(parseNumericMillion(value));
            else
              formattedValue = addCommasinPrice(parseNumericBillion(value));
          }
          historicalTableRowData.push(<td>{formattedValue}</td>)
        }
      tableData.push(<tr style={{background:i%2!==0 ? '#F7F7F8' : '#FFFFFF'}}>{historicalTableRowData}</tr>)
    })
    }
    return tableData;
  }
  const showProfileData=(data)=>{
    let tableData=[];
    if(Object.keys(data).length>0){
      profileType.forEach((type, i)=>{
        let value = null
        if(!data[type.key]){
          value = '--'
        }
        else if(isNaN(data[type.key])){
          value = data[type.key]
          if(type.format==='date'){
            value = format(new Date(value), 'dd MMMM yyyy')
          }
        }
        else{
          if(data[type.key]<=10000)
            value = addCommasinPrice(decimalAdjust('floor', data[type.key], -2));
          else if(data[type.key] < 1000000000)
            value = addCommasinPrice(parseNumericMillion(data[type.key]));
          else
            value = addCommasinPrice(parseNumericBillion(data[type.key]));
          
          if(type.format==='dollar'){
            value=`$${value}`;
          }
        }
        let card=(<div className={styles.statsCard}>
          <p style={{color:'#626777', lineHeight:'16px'}} className='m-0 pb-1'>{type.label}</p>
          <p className={`m-0 ${styles.cardValue}`}>{value}</p>
        </div>)
        tableData.push(<Col xl={4} lg={4} md={4} sm={6} xs={6}>{card}</Col>)
      })
    }
    return <Row className={styles.responsiveMargin}>{tableData}</Row>;
  }
  return (
    <React.Fragment>
      <CustomModal className={styles.statsModal} show={showModal} handleClose={handleClose} fromEtf={true} scrollable={true}>
        <div className={styles.statsHeader}>
          <span className={`p-0 ${styles.heading}`}>Statistics</span>
        </div>
        <Tabs activeKey={activeTab} id='stats-tab' className={styles.statsNavbar}
        onSelect={(k) => setActiveTab(k)}
        >
          <Tab eventKey='profile' title='ETF Details'>
            <div className='mt-4'>
              {showProfileData(etfData)}
            </div>
          </Tab>
          <Tab eventKey='historicalData' title='Historical Data' className='overflow-auto'>
            <Table striped className={`mt-4 ${styles.statsTable} ${styles.statsProfile}`}>
              <thead>
                <tr>
                  <th>Date Range</th>
                  {historicalDataType.map((type)=><th>{type.label}</th>)}
                </tr>
              </thead>
              <tbody>
                {showHistoricalData(etfData)}
              </tbody>
            </Table>
          </Tab>
        </Tabs>
      </CustomModal>
    </React.Fragment>
  )
}

export default StatsModal
