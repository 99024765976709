import React, { useEffect, useState } from 'react'
import { Row, Col } from 'react-bootstrap'
import styles from './stockAbout.module.css'
import MobileAboutSkeleton from '../../containers/StockDetail/MobileAboutSkeleton'

const MobileStockAbout = ({stockName, stockData = {}, etf=false,livePrice={} }) => {

	const [more, setMore] = useState('More')

  useEffect(()=>{
    setMore('More')
  },[stockName])

	let description = etf ? stockData ? stockData.BusinessDescription ? stockData.BusinessDescription : '' : '' :  stockData ? stockData.description ? stockData.description : '' : ''

  description = more === 'More' ? description.slice(0, 200) : description

	const handleNews = () => {
		more === 'More' ? setMore('Less') : setMore('More')
	}



	return (
		<>
			<Row className={styles.aboutDiv} >
				<Col className={`${styles.title}`}>About</Col>
			</Row>
			{
				<React.Fragment>
					<Row className={`${styles.desc}`}>
						<Col style={{ display: description ? 'none' : 'block' }}>
							<MobileAboutSkeleton />
						</Col>
						<Col style={{ display: description ? 'block' : 'none' }}>
							{description}
							{description.length <= 199 ? null : <span onClick={() => handleNews()} className={styles.know}>
								Know{` ${more}`}
							</span>}
						</Col>
					</Row>
				</React.Fragment>
			}
		</>
	)
}

export default MobileStockAbout
