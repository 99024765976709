import React,{useEffect,useState} from 'react'
import styles from './morningStar.module.css'
import { decimalAdjust } from '../../utils'

const MorningstarIndustry = ({industryData,stockName}) =>{
  const [updatedData, setUpdatedData] = useState([])
  const [flag,setFlag] = useState(false)

  useEffect(()=>{
    flag && setUpdatedData([])
  },[stockName,industryData])

  useEffect(()=>{
    let total = 0
    flag && industryData && industryData.slice(0,5).map((item,i)=>{
      total = total + item.weightagepct
    })

    flag && industryData && industryData.slice(0,5).map((item,i)=>{
      setUpdatedData((prevState) => (
      [...prevState,((item.weightagepct*100)/total)]));
    })
  },[industryData])

  useEffect(()=>{
    let total = 0
    industryData && industryData.slice(0,5).map((item,i)=>{
      total = total + item.weightagepct
    })

    industryData && industryData.slice(0,5).map((item,i)=>{
      setUpdatedData((prevState) => (
      [...prevState,((item.weightagepct*100)/total)]),setFlag(true));
    })

  },[])


  return(
    <>
      <div className={styles.industry}>
        Industry Allocation
      </div>
      {
        updatedData && industryData && updatedData.slice(0,5).map((item,i)=>{
          return(
            <>
              <div className={styles.barTitle}>{industryData[i] && industryData[i].industry && industryData[i].industry}</div>
              <div style={{display:"flex"}}>
              <div key={i} style={{height:"24px",width:`${item}%`,background: '#1E1F24', borderRadius:'4px',marginTop:"8px",marginBottom:'24px'}}></div>
              <span className={styles.barTitle} style={{marginLeft:"16px"}}>{`${decimalAdjust('floor',industryData[i] && industryData[i].industry && industryData[i].weightagepct,-2)}%`}</span>
              </div>
            </>
          )
        })
      }
    </>

  )
}

export default MorningstarIndustry
