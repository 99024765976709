import React, { useEffect, useState } from "react";
import styles from './AccountTransfer.module.css'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { format } from 'date-fns'
import QuestionMark from '../../../public/images/TooltipQuestion.svg'

const AccountStatus = ({callstatusApi,statusData}) => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [callstatusApi])

    return(
        <div className="mt-5">
            {statusData && statusData.length > 0 ? statusData.map((item,index) => {
                return(
                    <div className={styles.remittanceCard}>
                        <div className={styles.remittanceDetails}>
                            <div className='d-flex flex-column' style={{minWidth:0}}>
                                <span className={styles.main}>
                                    {format(new Date(item.createdAt), 'dd MMMM yyyy, p')}
                                </span>
                                <span className={`${styles.transID} ${styles.sub}`}>
                                    <span>Account Transfer Type: {item.acatType ? item.acatType : "--"}</span>
                                </span>
                            </div>
                            <div className='d-flex flex-column align-items-end'>
                                <span className={styles.main}>
                                    {item.sourceAccount ? item.sourceAccount  : "--"}
                                </span>
                                {item.status.toLowerCase() === "started" ?
                                <span className={styles.sub}>
                                    STARTED
                                </span>:
                                item.status.toLowerCase() === "pending" || item.status.toLowerCase() === "on_hold" ?
                                <span className={styles.sub} style={{color:"rgb(250, 93, 5)"}}>
                                    PENDING
                                </span>:
                                item.status.toLowerCase() === "rejected" ||  item.status.toLowerCase() === "failed"?
                                <span className={styles.sub} style={{color:"rgb(250, 39, 74)"}}>
                                    FAILED
                                    <OverlayTrigger
									placement='top'
									overlay={
										<Tooltip id='button-tooltip-2' style={{marginLeft:"5px"}}>
											{item.rejectionReason && item.rejectionReason !== null && item.rejectionReason}
										</Tooltip>
									}>
									<img style={{marginLeft:"5px",marginBottom:"2px", width:"12px"}} src={QuestionMark} className={styles.tooltipImage}></img>
								    </OverlayTrigger>
                                </span>:
                                item.status.toLowerCase() === "completed" ?
                                <span className={styles.sub} style={{color:"rgb(13, 192, 20)"}}>
                                    SUCCESSFUL
                                </span>: ""}
                            </div>
                        </div>
                    </div>
                )
            }) : <p className={styles.noDataFound}>No Records Found</p>}
        </div>
    )
}

export default AccountStatus
