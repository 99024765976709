import React, { useState, useEffect, useRef, useContext } from 'react'
import { useDispatch } from 'react-redux';
import { showFundModal } from '../../store/actions';
import { CashAccountReviewState, CashAccountRejectedState, CashAccountSignupState, CashAccountPausedState } from './NavigationStates';
import Dropdown from 'react-bootstrap/Dropdown';
import { useHistory } from 'react-router-dom'
import { logout } from '../../api'
import { toPascalCase , decimalAdjust, addCommasinPrice } from '../../utils';
import styles from './HeaderDropdown.module.css'
import navbarArrowDown from '../../public/images/navbarArrowDown.svg'
import navbarArrowUp from '../../public/images/navbarArrowUp.svg'
import stackIcon from '../../public/images/stackIcon.svg'
import themeIcon from '../../public/images/themeIcon.svg'
import portfoliIcon from '../../public/images/portfoliIcon.svg'
import ordersIcon from '../../public/images/ordersIcon.svg'
import usIcon from '../../public/images/usIcon.svg'
import transferIcon from '../../public/images/transferIcon.svg'
import recentIcon from '../../public/images/recentIcon.svg'
import addFundsIcon from '../../public/images/addFundsIcon.svg'
import manageFundsIcon from '../../public/images/manageFundsIcon.svg'
import addFundsGrey from '../../public/images/addFundsGrey.svg'
import accountIcon from '../../public/images/accountIcon.svg'
import dollarIcon from '../../public/images/dollarIcon.svg'
import rewardsIcon from '../../public/images/rewardsIcon.svg'
import reportIcon from '../../public/images/reportIcon.svg'
import logoutIcon from '../../public/images/logoutIcon.svg'

const Explore = () => {

    let history = useHistory()

    const [ show, setShow ] = useState(false)

    const exploreRef = useRef()
    useOnClickOutside(exploreRef,() => setShow(false))

    return (
        <Dropdown className={styles.headerDropdown} show={show} ref={exploreRef}>
            <Dropdown.Toggle id="dropdown-basic" className={show ? styles.buttonBackground : styles.dropdownButton} onClick={() => { setShow(!show)}}>
                <p className={show ? styles.textBlack : styles.textGrey}>Explore</p>
                <img src={show ? navbarArrowUp : navbarArrowDown} alt='arrow-down'/>
            </Dropdown.Toggle>

            <Dropdown.Menu>
                <div className={styles.menuItemContainer}>
                    <Dropdown.Item onClick={() => history.push('/allStacks')}>
                        <MenuItem image={stackIcon} title={'Curated Stacks'} desc={'Expert-Made Model Portfolios'}/>
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => history.push('/themes')}>
                        <MenuItem image={themeIcon} title={'Investment Themes'} desc={'Collections of Stocks & ETFs'}/>
                    </Dropdown.Item>
                </div>
                <Dropdown.Divider />
                <Dropdown.Item onClick={() => history.push('/explore')}>
                    <p className={styles.textBlue} style={{padding:"12px"}}>Explore All Stocks, ETFs & Stacks</p>
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    )
}

const InvestmentAccount = (props) => {

    let history = useHistory()
    const dispatchAction = useDispatch()

    const { portfolioSummary, pendingOrders, accountStatus, userInfo } = props
    const showFunds  = userInfo && userInfo.userRestriction ? userInfo.userRestriction.fund : false;
    const isAccountApproved = accountStatus && accountStatus === 2 ? true : false;

    const [ show, setShow] = useState(false)

    const portfolioAmount = portfolioSummary && portfolioSummary.totalCurrentValue ? portfolioSummary.totalCurrentValue.toFixed(2) : "";
    const totalInvestmentAmount = portfolioSummary && portfolioSummary.totalInvestmentAmount ? portfolioSummary.totalInvestmentAmount.toFixed(2) : "";

    const profitAndLoss = portfolioAmount && totalInvestmentAmount ?  decimalAdjust('floor',portfolioAmount - totalInvestmentAmount, -2) : ''

    const investRef = useRef()
    useOnClickOutside(investRef,() => setShow(false))

    return (
        <Dropdown className={styles.headerDropdown} show={show} ref={investRef}>
            <Dropdown.Toggle id="dropdown-basic" className={show ? styles.buttonBackground : styles.dropdownButton} onClick={() => { setShow(!show)}}>
                <p className={show ? styles.textBlack : styles.textGrey}>Investment Account</p>
                <img src={show ? navbarArrowUp : navbarArrowDown} alt='arrow-down'/>
            </Dropdown.Toggle>

            <Dropdown.Menu style={{minWidth:"230px"}}>
                {isAccountApproved ? (
                    <>
                        <div className={styles.menuItemContainer}>
                            <Dropdown.Item onClick={() => history.push('/portfolio')}>
                                <MenuItem image={portfoliIcon} title={'Portfolio'} desc={ portfolioAmount ? `$${addCommasinPrice(parseFloat(portfolioAmount))}`: '--'} text={profitAndLoss ? profitAndLoss > 0 ? `+$${addCommasinPrice(profitAndLoss)}` : `-$${addCommasinPrice(profitAndLoss).substring(1)}`: ''} textColor={profitAndLoss ? profitAndLoss > 0 ? '#0DC014' : '#FA274A' : '' }/>
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => history.push('/Orders')}>
                                <MenuItem image={ordersIcon} title={'Orders'}/>
                                {/* To be added after socket implementation */}
                                {/* desc={pendingOrders && pendingOrders !== 0 ? `• ${pendingOrders} Pending orders`: `No pending orders`} color={pendingOrders && pendingOrders !== 0 ? "#FA5D05": ''} */}
                            </Dropdown.Item>
                        </div>
                        <Dropdown.Divider />
                        <Dropdown.Item onClick={() => history.push('/funds')}>
                            <div className={styles.dropdownRow}>
                                <img src={manageFundsIcon} alt='icon' style={{marginRight:"9.5px"}}/>
                                <p className={styles.textBlue}>Manage Funds</p>
                            </div>
                        </Dropdown.Item>
                        <Dropdown.Divider />
                        <Dropdown.Item onClick={!showFunds ? ()=> {
                            dispatchAction(showFundModal({investment:true,selectFund:true}))
                            setShow(!show)
                        } : ""}>
                            <div className={styles.dropdownRow}>
                                <img src={addFundsIcon} alt='icon'style={{marginRight:"11px"}}/>
                                <p className={styles.textBlue}>Add Funds</p>
                            </div>
                        </Dropdown.Item>
                        {/* <Dropdown.Divider />
                        <Dropdown.Item onClick={() => history.push('/account-transfer')}>
                            <div className={styles.dropdownRow}>
                                <img src={manageFundsIcon} alt='icon' style={{marginRight:"9.5px"}}/>
                                <p className={styles.textBlue}>Account Transfer</p>
                            </div>
                        </Dropdown.Item> */}
                    </>
                ) : (
                    <div style={{opacity:"0.65"}}>
                        <div className={styles.menuItemContainer}>
                            <MenuItem image={portfoliIcon} title={'Portfolio'} desc={'--'}/>
                            <MenuItem image={ordersIcon} title={'Orders'} desc={'--'}/>
                        </div>
                        <Dropdown.Divider />
                        <div className={styles.dropdownRow}>
                            <img src={manageFundsIcon} alt='icon' style={{marginRight:"9.5px"}}/>
                            <p className={styles.textBlue}>Manage Funds</p>
                        </div>
                        <Dropdown.Divider />
                        <div className={styles.dropdownRow}>
                            <img src={addFundsIcon} alt='icon'style={{marginRight:"11px"}}/>
                            <p className={styles.textBlue}>Add Funds</p>
                        </div>
                        {/* <Dropdown.Divider />
                        <div className={styles.dropdownRow}>
                            <img src={manageFundsIcon} alt='icon' style={{marginRight:"9.5px"}}/>
                            <p className={styles.textBlue}>Account Transfer</p>
                        </div> */}
                    </div>
                )}
            </Dropdown.Menu>
        </Dropdown>
    )
}

const CashAccount = (props) => {

    let history = useHistory()
    const dispatchAction = useDispatch()

    const { cashAccount, transactions, accountStatus, CashAccountApiError, accountApproved, isSavingAccount, addedToWaitlist, isDisabled, addToWaitlist } = props

    const isAccountApproved = accountApproved && accountApproved === 2 ? true : false
    const showReviewState = isAccountApproved && ( accountStatus === 1 || isSavingAccount )
    const isCashAccountRejected = accountStatus === 3 || accountStatus === 4

    const [ show, setShow] = useState(false)

    const cashRef = useRef()
    useOnClickOutside(cashRef,() => setShow(false))

    const transferFundsButton = () => {
      if(window.location.pathname !== '/savings'){
        history.push('/savings?transferFund=true')
      } else{
        props.openTranferFundsHeader()
      }
    }

    return (
        <Dropdown className={styles.headerDropdown} show={show} ref={cashRef}>
            <Dropdown.Toggle id="dropdown-basic" className={show ? styles.buttonBackground : styles.dropdownButton} onClick={() => { setShow(!show)}}>
                <p className={show ? styles.textBlack : styles.textGrey}>Cash Account</p>
                <img src={show ? navbarArrowUp : navbarArrowDown} alt='arrow-down'/>
            </Dropdown.Toggle>

            {/* <Dropdown.Menu>
                {accountStatus === 2 ? (
                    <>
                        <div className={styles.menuItemContainer}>
                            <Dropdown.Item onClick={() => history.push('/savings')}>
                                <MenuItem image={usIcon} title={'Account Details'} desc={cashAccount ? `$${cashAccount}` : '--'}/>
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => history.push('/savings')}>
                                <MenuItem image={recentIcon} title={'Recent Transactions'} desc={transactions ? `${transactions} in the last 30 days` : '--'}/>
                            </Dropdown.Item>
                        </div>
                        <Dropdown.Divider />
                        <Dropdown.Item onClick={CashAccountApiError ? undefined : transferFundsButton}>
                            <div className={styles.dropdownRow}>
                                <img src={transferIcon} alt='icon' style={{marginRight:"9.5px"}}/>
                                <p className={styles.textBlue} style={{color: CashAccountApiError ? "grey" : ""}}>Transfer Funds</p>
                            </div>
                        </Dropdown.Item>
                        <Dropdown.Divider />
                        <Dropdown.Item onClick={()=> {
                            dispatchAction(showFundModal({saving:true}))
                            setShow(!show)
                        }} disabled>
                            <div className={styles.dropdownRow}>
                                <img src={addFundsIcon} alt='icon'style={{marginRight:"11px"}}/>
                                <p className={styles.textBlue} style={{color: "grey"}}>Add Funds</p>
                            </div>
                        </Dropdown.Item>
                    </>
                ): isCashAccountRejected ? (
                    <div style={{minWidth:"256px"}}>
                        <CashAccountRejectedState/>
                    </div>
                ): showReviewState ? (
                    <div style={{minWidth:"256px"}}>
                        <CashAccountReviewState/>
                    </div>
                ) :(
                    <div style={{minWidth:"256px"}}>
                        <CashAccountSignupState accountApproved={isAccountApproved}/>
                    </div>
                )}
            </Dropdown.Menu> */}

            <Dropdown.Menu>
                <div style={{minWidth:"256px"}}>
                    <CashAccountPausedState isDisabled={isDisabled} addToWaitlist={addToWaitlist} addedToWaitlist={addedToWaitlist}/>
                </div>
            </Dropdown.Menu>
        </Dropdown>
    )
}

const ProfileDropdown = (props) => {

    let history = useHistory()
    const ssoShown = sessionStorage.getItem('Sso_flag') ? sessionStorage.getItem('Sso_flag') : false
    let logoutpage = sessionStorage.getItem('logoutPage') ? sessionStorage.getItem('logoutPage') : ''

    const logoutPageCal = () => {
      if(logoutpage){
        window.location.href=logoutpage
      }else{
        window.location.href='/logout'
      }
    }

      const onClickLogout = () => {
        var token = JSON.parse(sessionStorage.getItem('token'))

          //webengage logout
          // eslint-disable-next-line no-undef
        webengage.user.logout();

        var body = {
            accessToken: token && token.accessToken
        }

        logout(JSON.stringify(body)).then(response => {
            sessionStorage.clear()
            localStorage.clear()

            if(ssoShown){
            logoutPageCal()
            }
            else{
            history.push('/')
            }
        })
      }

    const onCloseApp = () => {
      if(localStorage.getItem('fromStockalApp') === 'Y' || localStorage.getItem('fromStockalApp') === 'y'){
        logoutPageCal()
        window.closeSite.postMessage('close')
      }
      else{
        onClickLogout()
      }
    }

    const { firstName, lastName, accountNumber } = props

    const [ show, setShow] = useState(false)

    const profileRef = useRef()
    useOnClickOutside(profileRef,() => setShow(false))

    return (
        <Dropdown className={styles.headerDropdown} show={show} ref={profileRef} alignRight>
            <Dropdown.Toggle id="dropdown-basic" className={styles.dropdownButton} onClick={() => { setShow(!show)}}>
                <div className={styles.profileName}>
                    {firstName && lastName ? `${firstName[0].toUpperCase()}${lastName[0].toUpperCase()}` : '--'}
                </div>
                <img src={show ? navbarArrowUp : navbarArrowDown} alt='arrow-down'/>
            </Dropdown.Toggle>

            <Dropdown.Menu style={{minWidth:"280px"}}>
                <Dropdown.Item>
                    <div className={styles.profileHeader}>
                        <p className={`${styles.textBlack} ${styles.dropdownHeader}`}>{ firstName && lastName ? `${toPascalCase(firstName)} ${toPascalCase(lastName)}` : '--'}</p>
                        <p className={styles.textGrey}>{accountNumber ? accountNumber : '--'}</p>
                    </div>
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item onClick={() => history.push('/funds')}>
                    <div className={styles.dropdownRow} style={{paddingLeft:"15px"}}>
                        <img src={addFundsGrey} alt='icon' style={{marginRight:"11px"}} width="12px"/>
                        <p className={styles.textGrey}>Add Funds</p>
                    </div>
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item onClick={() => history.push('/profile')}>
                    <div className={styles.dropdownRow} style={{paddingLeft:"14.25px"}}>
                        <img src={accountIcon} alt='icon' style={{marginRight:"10.25px"}} width="13.5px"/>
                        <p className={styles.textGrey}>Account Settings</p>
                    </div>
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item onClick={() => history.push('/remittance')}>
                    <div className={styles.dropdownRow} style={{paddingLeft:"12.75px"}}>
                        <img src={dollarIcon} alt='icon' style={{marginRight:"8.75px"}} width="16.5px"/>
                        <p className={styles.textGrey}>Remittance History</p>
                    </div>
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item onClick={() => history.push('/reports')}>
                    <div className={styles.dropdownRow} style={{paddingLeft:"14.25px"}}>
                        <img src={reportIcon} alt='icon' style={{marginRight:"10.25px"}} width="13.5px"/>
                        <p className={styles.textGrey}>Reports & Statements</p>
                    </div>
                </Dropdown.Item>
                <Dropdown.Divider/>
                {ssoShown ? (
                    <Dropdown.Item onClick={onCloseApp}>
                        <div className={styles.dropdownRow} style={{paddingLeft:"13.5px"}}>
                            <img src={logoutIcon} alt='icon' style={{marginRight:"9.5px"}} width="15px"/>
                            <p className={styles.textRed}>Close</p>
                        </div>
                    </Dropdown.Item>
                ) : (
                    <Dropdown.Item onClick={onClickLogout}>
                        <div className={styles.dropdownRow} style={{paddingLeft:"13.5px"}}>
                            <img src={logoutIcon} alt='icon' style={{marginRight:"9.5px"}} width="15px"/>
                            <p className={styles.textRed}>Logout</p>
                        </div>
                    </Dropdown.Item>
                )}
            </Dropdown.Menu>
        </Dropdown>
    )
}


const MenuItem = (props) => {

    const { image, title, desc, text, color, textColor }  = props

    return (
        <div className={styles.menuContainer}>
            <div className={styles.menuImage}>
                <img src={image} alt='menu-icon'/>
            </div>
            <div className={styles.menuContent}>
                <p className={styles.textBlack}>{title}</p>
                <div style={{display:"flex",gap:"6px"}}>
                    <p style={{color:color ? color : '#767C8F' }}>{desc}</p>
                    <p style={{color:textColor}}>{text ? text : ''}</p>
                </div>
            </div>
        </div>
    )
}


function useOnClickOutside(ref, handler) {
    useEffect(
      () => {
        const listener = (event) => {
          if (!ref.current || ref.current.contains(event.target)) {
            return
          }
          handler(event)
        }
        document.addEventListener('mousedown', listener)
        document.addEventListener('touchstart', listener)
        return () => {
          document.removeEventListener('mousedown', listener)
          document.removeEventListener('touchstart', listener)
        }
      },
      [ref, handler]
    )
}

export { Explore, InvestmentAccount, CashAccount, ProfileDropdown, MenuItem}

