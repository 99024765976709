import React,{useState,useEffect} from 'react'
import {Row,Col, OverlayTrigger, Tooltip} from 'react-bootstrap'
import Rating from 'react-rating'
import yellowStar from '../../public/images/yellowStar.svg'
import whiteStar from '../../public/images/whiteStar.svg'
import morningwStar from '../../public/images/morningStar.svg'
import styles from './morningStar.module.css'
// import seeMore from '../../public/images/seemoreNew.svg'
// import hide from '../../public/images/hideIconNew.svg'
import info from '../../public/images/info.svg'
// import MorningStarDropDown from './morningStarDropDown'
// import moment from 'moment'
import {format} from 'date-fns'
import { getCurrentAccountStatusLink } from '../../common'
import { MORNING_STAR_FEATURE } from '../../constants'

import { useHistory } from 'react-router-dom'



const MorningstarRating = ({ratingData,stockName,premiumFeatures,activePlan}) =>
{
  // const [ratingNumber, setRatingNumber] = useState()
 // const [active, setActive] = useState(false)
 // const [dateArray,setDateArray] = useState([])
  const [formattedRating,setFormattedRating] = useState({})
  const [activeOption, setActiveOption] = useState(null)
  // const [buttonText,setButtonText] = useState()
  // const [flag,setFlag] = useState(false)
  const [hover, setHover] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const yearMap = {
    rating_overall: 'Overall',
    rating_3y: '3 Year',
    rating_5y: '5 Year',
    rating_10y: '10 Year'
  }
  const dateFormatter = (data) => {
    let year = data.slice(0,4)
    let month = data.slice(5,7)
    let day = data.slice(8,11)
    // month = moment().month(month-1).format("MMM")
    return format(new Date(year, month-1, day), 'dd MMM yyyy')
  }


  let history = useHistory()

  // useEffect(()=>{
  //   flag && setDateArray([])
  // },[stockName,ratingData])

  useEffect(()=>{
    let data = {};
    if(ratingData && ratingData.length > 0){
      ratingData.forEach((item,i)=>{
          data[item.ratingyear]= {
          date:dateFormatter(item.asofdate.slice(0,10)),
          rating: item.rating,
        }
      });
    }
    setFormattedRating(data);
    setActiveOption('rating_overall');
  },[ratingData])


  // useEffect(()=>{
  //   let data = {};
  //   if(ratingData && ratingData.length > 0){
  //     ratingData.forEach((item,i)=>{
  //         data[item.ratingyear]= {
  //         date:dateFormatter(item.asofdate.slice(0,10)),
  //         rating: item.rating,
  //       }
  //     });
  //   setFormattedRating(data, setFlag(true));
  //   setActiveOption('rating_overall');
  // },[])

  const handleClose = () => {
    setTimeout(() => {
      setHover(false)
    }, 1000)
  }


  // useEffect(()=>{
  //   dateArray && dateArray[0] && setRatingNumber(dateArray[0].rating)
  //   dateArray && dateArray[0] && setButtonText(dateArray[0].date)
  // },[dateArray])

  const redirectProfile = async() => {
    const link = await getCurrentAccountStatusLink();
    history.push(link)
  }

  return(
    <div className={styles.raingMargin}>
      {premiumFeatures.includes(MORNING_STAR_FEATURE) && activePlan === 'active'?
      <Row>
        <Col>
          <div className={styles.ratingOption}>
              {Object.keys(formattedRating).map((year)=>{
                if(formattedRating[year].rating !== null)
                  return <button className={`${styles.yearButton} ${activeOption===year?styles.activeOption:null}`} onClick={()=>setActiveOption(year)}>{yearMap[year]}</button>
              })}
          </div>
        </Col>
      </Row>: ""}
      <Row>
        {premiumFeatures.includes(MORNING_STAR_FEATURE) && activePlan === 'active' ?
        <Col>
        <div>
          {Object.keys(formattedRating).length > 0 ?
            <div className={styles.ratingStar}>
              <Rating fractions={2} initialRating={formattedRating[activeOption].rating} readonly emptySymbol={<img src={whiteStar} className={styles.imageResponsive}></img>} fullSymbol={<img src={yellowStar} className={styles.imageResponsive}></img>}/>
              <OverlayTrigger
                placement='bottom'
                trigger={['hover', 'focus','click']}
                overlay={
                  <Tooltip>
                  The Morningstar Rating<sup>TM</sup> is a quantitative assessment of a fund’s past performance (both return and risk)
                  </Tooltip>
                }>
                <img src={info} className={styles.toopTipImage}/>
              </OverlayTrigger>
              <div className={`${styles.morningText} ${styles.responsiveInline}`}>
                <div>Morningstar Rating as of&nbsp;</div><div>{formattedRating[activeOption].date}</div>
              </div>
            </div>
            :
            <>
              <span className={styles.unratedText}>Unrated</span>
              <div className={styles.morningText}>
                As of {format(new Date(), 'dd MMMM yyyy')}
              </div>
            </>
            }
            {/* {
              ratingNumber === null || ratingData.length === 0 ?
              <div className={styles.responsiveInline}>
              <div className={styles.morningText}>
                Morningstar Rating  <div className={styles.noRatingText}>(Not Rated)</div>
              </div>
              <div className={styles.noRating}>Not Rated</div>
              </div>
            :
            <div className={styles.responsiveInline}>
              <div className={styles.morningText}>
                Morningstar Rating as of
              </div>
              <div>
              <div className={styles.dateCss} onClick={()=>ratingData && ratingData.length > 1 && setActive(!active)}>
                <button className={styles.buttonCss}>{buttonText}</button>
                <img src={active ? hide : seeMore} style={{marginLeft:"10px",display:ratingData && ratingData.length > 1 ? 'block' :"none"}}/>
              </div>
              <div style={{display:active?'block':'none'}} className={styles.outerDiv}>
                {
                  dateArray.map((item,i)=>{
                    return <MorningStarDropDown item={item.date} i={i} setButtonText={setButtonText} setActive={setActive} setRatingNumber={setRatingNumber} rating={item.rating}/>
                  })
                }
              </div>
              </div>
            </div> } */}
          </div>
          </Col>
        :
        <>
        <Col md={12}  style={{webkitFilter: "blur(3px)"}}>
          <div className={styles.ratingOption}>
            <button className={`${styles.yearButton} ${styles.activeOption}`}>Overall</button>
            <button className={`${styles.yearButton} ${styles.activeOption}`}>3Year</button>
            <button className={`${styles.yearButton} ${styles.activeOption}`}>5Year</button>
            <button className={`${styles.yearButton} ${styles.activeOption}`}>10Year</button>
          </div>
        </Col>
        <Row>
        <Col>
        <div className='pl-3'>
          <span onMouseEnter={() =>  setHover(true)}
          onMouseLeave={()=> handleClose() }
          // onMouseLeave={() => activePlan === 'active' ? !exclusive && setTimeout(() => {
          //   setHover(false)
          // }, 1000) : setTimeout(() => {
          //   setHover(false)
          // }, 00)}
          >
          <Rating style={{webkitFilter: "blur(2px)"}} fractions={2} initialRating={null} readonly emptySymbol={<img src={whiteStar} className={styles.imageResponsive}></img>} fullSymbol={<img src={yellowStar} className={styles.imageResponsive}></img>}/>
          </span>
          <OverlayTrigger
            placement='bottom'
            trigger={['hover', 'focus','click']}
            overlay={
              <Tooltip>
              The Morningstar Rating<sup>TM</sup> is a quantitative assessment of a fund’s past performance (both return and risk)
              </Tooltip>
            }>
            <img src={info} className={styles.toopTipImage}/>
          </OverlayTrigger>
          <div className={styles.responsiveInline} style={{webkitFilter: "blur(3px)"}}>
            <div className={styles.morningText}>
              Morningstar Rating as of
            </div>
            <div>
            <div className={styles.dateCss} style={{cursor:"auto"}}>
              <div className={styles.buttonCss}>XX XXX XXXX</div>
            </div>
            {/* <div style={{display:active?'block':'none'}} className={styles.outerDiv}>
              {
                dateArray.map((item,i)=>{
                  return <MorningStarDropDown item={item.date} i={i} setButtonText={setButtonText} setActive={setActive} setRatingNumber={setRatingNumber} rating={item.rating}/>
                })
              }
            </div> */}
            </div>
          </div>
          {/* popup mto upgrade */}
          {hover ?
            <div className={styles.customTooltip}>
              <div className={styles.customTooltipArrow}>
              </div>
              <Row style={{ textAlign: 'center', margin: '0px', paddingLeft: '20px', paddingTop: '20px' }}>
                <Col md={11} style={{ padding: '0px' }}>
                  <div className={styles.customTooltipText}>
                    Unlock access to Morning Star rating
                  </div>
                  <div className={styles.icon} onClick={redirectProfile} style={{ cursor: 'pointer' }}>
                    <button className={`btn btn-warning ${styles.customTooltipButtonText}`}>UPGRADE PLAN</button>
                  </div>
                </Col>
              </Row>
            </div> : null
          }
        </div></Col></Row></>}
        <Col style={{textAlign:'end'}} className={Object.keys(formattedRating).length>0 && premiumFeatures.includes(MORNING_STAR_FEATURE) && activePlan === 'active' ?styles.ratingStar:styles.morningStarLogoMarginUnrated}>
          <div style={{display:'inline-block',textAlign:"start"}}>
          <div className={styles.provided}>Data Provided By</div>
          <img src={morningwStar} className={styles.morningStarCss}/>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default MorningstarRating
