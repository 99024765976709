import React from 'react'
import styles from './morningStar.module.css'
import {Row,Col} from 'react-bootstrap'
import { decimalAdjust } from '../../utils'

const MorningstarAsset = ({assetData}) =>
{
  return(
    <>
    <div className={styles.asset}>
      Asset Allocation
    </div>
    { assetData.length ? assetData[0].stockpct ? decimalAdjust('floor',assetData[0].stockpct,-2) !== 0 ?
      ( <Row className={styles.assetRow}>
          <Col className={styles.assetTitle}>
          Stock
          </Col>
          <Col className={styles.assetTitle} style={{textAlign:"end"}}>
          {`${decimalAdjust('floor',assetData[0].stockpct,-2)}%`}
          </Col>
        </Row>
      ): <div></div> : <div></div> : <div></div>
    }
    { assetData.length  ? assetData[0].cashpct ? decimalAdjust('floor',assetData[0].cashpct,-2) !== 0 ?
      ( <Row className={styles.assetRow}>
          <Col className={styles.assetTitle}>
          Cash Investments
          </Col>
          <Col className={styles.assetTitle} style={{textAlign:"end"}}>
          {`${decimalAdjust('floor',assetData[0].cashpct,-2)}%`}
          </Col>
        </Row>
      ) : <div></div> : <div></div> : <div></div>
    }
    { assetData.length ? assetData[0].bondpct ? decimalAdjust('floor',assetData[0].bondpct,-2) !== 0 ?
      ( <Row className={styles.assetRow}>
          <Col className={styles.assetTitle}>
          Bond
          </Col>
          <Col className={styles.assetTitle} style={{textAlign:"end"}}>
          {`${decimalAdjust('floor',assetData[0].bondpct,-2)}%`}
          </Col>
        </Row>
      ) : <div></div> : <div></div> : <div></div>
    }
    { assetData.length  ? assetData[0].convertiblestockpct ? decimalAdjust('floor',assetData[0].convertiblestockpct,-2) !== 0 ?
      ( <Row className={styles.assetRow}>
          <Col className={styles.assetTitle}>
          Convertible Stocks
          </Col>
          <Col className={styles.assetTitle} style={{textAlign:"end"}}>
          {`${decimalAdjust('floor',assetData[0].convertiblestockpct,-2)}%`}
          </Col>
        </Row>
      ): <div></div> : <div></div> : <div></div>
    }
    { assetData.length ? assetData[0].preferredstockpct ? decimalAdjust('floor',assetData[0].preferredstockpct,-2) !== 0 ?
      ( <Row className={styles.assetRow}>
          <Col className={styles.assetTitle}>
          Preferred stocks
          </Col>
          <Col className={styles.assetTitle} style={{textAlign:"end"}}>
          {`${decimalAdjust('floor',assetData[0].preferredstockpct,-2)}%`}
          </Col>
        </Row>
      ): <div></div> : <div></div> : <div></div>
    }
    { assetData.length ? assetData[0].otherspct ? decimalAdjust('floor',assetData[0].otherspct,-2) !== 0 ?
      ( <Row className={styles.assetRow}>
          <Col className={styles.assetTitle}>
          Other
          </Col>
          <Col className={styles.assetTitle} style={{textAlign:"end"}}>
          {`${decimalAdjust('floor',assetData[0].otherspct,-2)}%`}
          </Col>
        </Row>
      ): <div></div> : <div></div> : <div></div>
    }
    </>
  )
}

export default MorningstarAsset
