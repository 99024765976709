import React from 'react'
import {Row,Col} from 'react-bootstrap'
import styles from './morningStar.module.css'
import { decimalAdjust } from '../../utils'
import moment from 'moment'

const Holding = ({assetData,holdingData}) => {

  const dateFormatter = (data) => {
    let year = data.slice(0,4)
    let month = data.slice(5,7)
    let day = data.slice(8,10)
    month = moment().month(month-1).format("MMM")
    return `${day} ${month} ${year}`
  }

  return(
    <Row className={styles.holdingResponsive}>
      <Col xs={7} md={6}>
        <div>
        <span className={styles.holding}>Holdings</span>
        <span className={styles.net} style={{marginLeft:"8px"}}>{`[As of ${dateFormatter(holdingData)}]`}</span>
        </div>
      </Col>
      <Col className={styles.netResponsive}>
        <div className={styles.net} style={{display:assetData ? assetData.length ?  assetData[0].top10hidassetpct ? 'block' : 'none' : 'none' : 'none'}}>
          {`Net assets of 10 largest holdings ${assetData && assetData.length &&  assetData[0].top10hidassetpct && decimalAdjust('floor',assetData[0].top10hidassetpct,-2)}%`}
        </div>
      </Col>
    </Row>
  )
}

export default Holding
