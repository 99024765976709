import React,{useEffect, useState} from 'react'
import MorningstarRating from './MorningstarRating'
import Holding from './holding'
import HoldingTable from './holdingTable'
import MorningstarPieChart from './morningstarPieChart'
import MorningstarAsset from './morningstarAsset'
import MorningstarIndustry from './morningstarIndustry'
import blurrImage from '../../public/images/pieBlurr.png'
import styles from './morningStar.module.css'
import { Row, Col } from 'react-bootstrap'
import researchImage from '../../public/images/research.png'
import { useHistory } from 'react-router-dom'
import { getCurrentAccountStatusLink } from '../../common'
import { MORNING_STAR_FEATURE } from '../../constants'

const MorningStar = ({stockName,activePlan,premiumFeatures,ratingData,sectorData,holdingData,assetData,industryData,siteFeatures}) =>
{

  let history = useHistory()
  const redirectProfile = async() => {
    //logic to redirect user based on account status
    const link = await getCurrentAccountStatusLink();
    history.push(link)
  }

  return(
    <>
    <div>
      { ratingData && ratingData.length > 0 || holdingData && holdingData.length > 0 ||
        sectorData && sectorData.length > 0 || assetData && assetData.length > 0 ||
        industryData && industryData.length > 0 &&
        <hr className={`${styles.shareLine}`} style={{marginBottom:"0px"}}/>
      }
      {/* {ratingData && ratingData.length > 0 && <MorningstarRating stockName={stockName} ratingData={ratingData} exclusive={exclusive} activePlan={activePlan}/>} */}
      {siteFeatures && siteFeatures.includes("morningstarRatings") ?
      <MorningstarRating stockName={stockName} ratingData={ratingData} premiumFeatures={premiumFeatures} activePlan={activePlan}/> : ""}
      {holdingData && holdingData.length > 0 && siteFeatures && siteFeatures.includes("holdings") ? <Holding assetData={assetData} holdingData={holdingData && holdingData.length && holdingData[0].asofdate}/> : ""}
      {holdingData && holdingData.length > 0 && siteFeatures && siteFeatures.includes("holdings") ? <HoldingTable holdingData={holdingData} premiumFeatures={premiumFeatures} activePlan={activePlan}/> : ""}
      {
        premiumFeatures.includes(MORNING_STAR_FEATURE) && activePlan === 'active'
        ? ( <div>
              {
                sectorData && sectorData.length > 0 && siteFeatures && siteFeatures.includes("sectorAllocation") ? <MorningstarPieChart sectorData={sectorData}/> : ""
              }
              {
                assetData && assetData.length > 0 && siteFeatures && siteFeatures.includes("assetAllocation") ? <MorningstarAsset assetData={assetData}/> : ""
              }
              {
                industryData && industryData.length > 0 && siteFeatures && siteFeatures.includes("IndustryAllocation") ? <MorningstarIndustry industryData={industryData} stockName={stockName}/> : ""
              }
            </div>

          )
        : <div style={{backgroundImage:`url(${blurrImage})`}} className={styles.blurrImage}>
            <Col style={{padding:'0px'}}>
              <Row style={{margin:'0px'}} className={styles.cardBorder}>
                <Col xs={12} md='auto' style={{textAlign:"center"}}>
                  <img src={researchImage} className={styles.imagePadding}/>
                </Col>
                <Col className={styles.cardContent}>
                  <div className={styles.header}>
                    Make better investment decisions
                  </div>
                  <div className={styles.description}>
                    Unlock access to in-depth research by
                    industry professionals and Insider transaction data.
                  </div>
                  <div className={styles.buttonDist}>
                  <div className={`${styles.icon}`} onClick={redirectProfile} style={{cursor:'pointer'}}>
                    <button
                      className={`${styles.plan}`}
                    >
                      UPGRADE PLAN
                    </button>
                  </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </div>
      }
       <div className={styles.allRights}>
                  2021 Morningstar. All Rights Reserved. The information, data, analyses and opinions (“Information”) contained herein: (1) include the proprietary information of Morningstar and its content providers; (2) may not be copied or redistributed except as specifically authorised; (3) do not constitute investment advice; (4) are provided solely for informational purposes; (5) are not warranted to be complete, accurate or timely; and (6) may be drawn from fund data published on various dates. Morningstar is not responsible for any trading decisions, damages or other losses related to the Information or its use. Please verify all of the Information before using it and don’t make any investment decision except upon the advice of a professional financial adviser. Past performance is no guarantee of future results. The value and income derived from investments may go down as well as up.
      </div>
    </div>

      </>
  )
}

export default MorningStar







